import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { mapAllowedSectionsToMenu } from "../const/sidebar";
import Breadcrumb from "./Breadcrumb";

const BackendMenuResponse = () => {
  const allowed_sections = useSelector((state) => {
    return state?.rolePermission?.allowed_sections ?? [];
  });

  const navItems = useMemo(
    () => mapAllowedSectionsToMenu(allowed_sections),
    [allowed_sections]
  );

  const currentPath = window.location.pathname; // Use current route path

  return (
    <div style={{ padding: "20px" }}>
      <Breadcrumb menu={navItems} currentPath={currentPath} />
    </div>
  );
};

export default BackendMenuResponse;
