import { Avatar, Box, Stack, styled, Typography } from "@mui/material";
import React from "react";
import midRUnFastIcon from "../assets/mdi_run-fast.svg";

// Styled components using MUI's styled API
const FilterContainer = styled(Box)(({ theme }) => ({
  boxSizing: "border-box",
  display: "inline-flex",
  height: "30px",
  padding: "5px 19px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  border: `1px solid ${theme.palette.grey[400]}`,
  backgroundColor: theme.palette.common.white,
  gap: "5px",
}));

const IconContainer = styled(Box)(({ theme }) => ({
  boxSizing: "border-box",
  padding: "5px",
  borderRight: `2px solid ${theme.palette.grey[400]}`,
  display: "flex",
  alignItems: "center",
}));

const FilterItemContainer = styled(Box)({
  boxSizing: "border-box",
  display: "flex",
  gap: "12px",
  padding: "2px 0",
  marginLeft: "8px",
  alignItems: "center",
});

const CustomCheckbox = styled("input")({
  margin: 0,
  padding: 0,
  height: "15px",
  width: "15px",
  cursor: "pointer",
});

const FilterLabel = styled(Typography)(({ theme }) => ({
  fontFamily: "proxima-nova",
  color: "#858D9D",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textTransform: "capitalize",
  "& label": {
    display: "block",
    cursor: "pointer",
  },
}));

const QuickFilter = ({
  quickFilterConfig = [],
  quickfilter = [],
  handleTicketStatus,
  icon,
}) => {
  return (
    <FilterContainer>
      {icon && (
        <IconContainer>
          <Box
            component="img"
            src={icon}
            sx={{
              width: 24,
              height: 24,
              marginRight: "8px",
            }}
          />
        </IconContainer>
      )}

      <Stack direction="row" alignItems="center" gap="10px">
        {quickFilterConfig.map((filter) => (
          <FilterItemContainer key={filter.label}>
            <CustomCheckbox
              type="checkbox"
              value={filter.value}
              id={filter.label}
              checked={quickfilter.includes(filter.value)}
              onChange={handleTicketStatus}
            />
            <FilterLabel>
              <label htmlFor={filter.label}>{filter.label}</label>
            </FilterLabel>
          </FilterItemContainer>
        ))}
      </Stack>
    </FilterContainer>
  );
};

export default QuickFilter;
