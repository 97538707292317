import { toast } from "react-toastify";
import CustomToast from "../components/Toast/CustomToast";
import { getErrorMessage } from "./toastUtils";

const notify = (
  title = "",
  description = "",
  config = {},
  status = "success"
) => {
  const borderLeft =
    status === "success"
      ? "#0ACD20"
      : status === "error"
      ? "#FF4343"
      : "#FFC940";

  toast(<CustomToast />, {
    closeButton: false,
    hideProgressBar: true, // Disable the progress bar
    className: "custom-toast",
    bodyClassName: "custom-body",
    data: {
      status,
      title,
      description,
    },
    style: {
      boxSizing: "border-box",
      borderLeft: `5px solid ${borderLeft}`,
    },
    ...config,
  });
};

export const successToast = (title = "", description = "", config = {}) => {
  notify(title, description, config, "success");
};

export const warningToast = (title = "", description = "", config = {}) => {
  notify(title, description, config, "warning");
};

export const errorToast = (title = "", description = "", config = {}) => {
  const descriptionMessage =
    typeof description === "object"
      ? getErrorMessage(description)
      : description;
  notify(title, descriptionMessage, config, "error");
};

export const notifyToast = {
  success: successToast,
  timeout: warningToast,
  error: errorToast,
};
