import axiosInstance from "../utils/axiosConfig";

const postComments = async (path, data, config = {}, emptyState = () => {}) => {
  try {
    const response = await axiosInstance.post(path, data, config);
    emptyState();
    return response.data; // Return the response data
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 2xx
      console.error("Response error:", error.response.data);
    } else if (error.request) {
      // Request was made but no response received
      console.error("No response:", error.request);
    } else {
      // Something else happened
      console.error("Error:", error.message);
    }
    throw error; // Re-throw the error for further handling
  }
};

export default postComments;
