import React, { useState, useEffect, useMemo } from "react";
import BlockIcon from "@mui/icons-material/Block";

import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useDebounce from "../hooks/useDebounce";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BlockDatesModel from "./BlockDatesModel";
import RescheduleAppointmentModel from "./RescheduleAppointmentModel";
import DeleteIcon from "@mui/icons-material/Delete";
import axiosInstance from "../utils/axiosConfig";
import removeSlugFromString from "../utils/removeSlugFromString";
import CustomConfirmationDialog from "./CustomConfirmationDialog";
import formatDate from "../utils/formateDate";
import SearchActionComponent from "./reusableComponents/SearchActionComponent";
import { BootstrapTooltip } from "./DetailsSidePanelChart";
import usePermission from "../hooks/usePermission";
import FilteredResults from "./FilteredResults";
import CustomFilter from "./reusableComponents/Filter/CustomFilter";
import { customeDateHandler } from "../const/commonfilterobject";
import DataTable from "./DataTable";
import { setQuery } from "../utils/queryHelpers";
import CommonTableHead from "./CommonTableHead";
import { PrimaryButton, SecondaryButton } from "./button";
import { CustomMenu } from "./Menu";
import { notifyToast } from "../utils/notify";

const AppointmentAction = ({
  handleClose,
  anchorEl,
  setBlock,
  params,
  setDeleteModel,
  status,
  permission = {},
  handleRescheduleAppointmentClick,
}) => {
  return (
    <CustomMenu
      id="demo-positioned-menu"
      anchorEl={anchorEl}
      open={anchorEl ? true : false}
      onClose={handleClose}
    >
      {permission?.UPDATE && (
        <MenuItem
          onClick={() => {
            setBlock(true);
            handleClose();
          }}
        >
          {" "}
          <Button
            sx={{
              color: "#000",
              fontFamily: "Proxima Nova",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "175%",
            }}
            variant="text"
            startIcon={<BlockIcon />}
          >
            {params?.row?.account_status === ("INACTIVE" || "ACTIVE")
              ? "Block"
              : "UnBlock"}
          </Button>
        </MenuItem>
      )}
      {permission?.DELETE && (
        <MenuItem
          onClick={() => {
            setDeleteModel(true);
            handleClose();
          }}
        >
          <Button
            sx={{
              color: "#000",
              fontFamily: "Proxima Nova",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "175%",
            }}
            variant="text"
            startIcon={<DeleteIcon />}
          >
            Delete Driver
          </Button>
        </MenuItem>
      )}

      {permission?.UPDATE && (
        <MenuItem>
          <Button
            startIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  d="M1.66699 10.4997C1.66699 7.35698 1.66699 5.78563 2.6433 4.80932C3.61961 3.83301 5.19096 3.83301 8.33366 3.83301H11.667C14.8097 3.83301 16.381 3.83301 17.3573 4.80932C18.3337 5.78563 18.3337 7.35698 18.3337 10.4997V12.1663C18.3337 15.309 18.3337 16.8804 17.3573 17.8567C16.381 18.833 14.8097 18.833 11.667 18.833H8.33366C5.19096 18.833 3.61961 18.833 2.6433 17.8567C1.66699 16.8804 1.66699 15.309 1.66699 12.1663V10.4997Z"
                  stroke="black"
                  stroke-width="1.5"
                />
                <path
                  d="M5.83301 3.83301V2.58301"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M14.167 3.83301V2.58301"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <circle
                  cx="13.75"
                  cy="14.25"
                  r="1.25"
                  stroke="black"
                  stroke-width="1.5"
                />
                <path
                  d="M2.08301 8H17.9163"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            }
            sx={{
              color: "#000",
              fontFamily: "Proxima Nova",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "175%",
            }}
            onClick={() => {
              handleRescheduleAppointmentClick();
              handleClose();
            }}
          >
            Reschedule
          </Button>
        </MenuItem>
      )}
    </CustomMenu>
  );
};

const AppointmentDocumentStatus = ({
  appointmentId,
  value = "--",
  getAppointments = () => {},
  authObj,
}) => {
  let showvalue = "--";
  if (value || value === false) {
    showvalue = value === false ? "no submitted" : "submitted";
  }
  const [documentStatus, setDocumentStatus] = useState(showvalue);

  const setAppointmentDocumentStatus = async (updateStatus) => {
    if (updateStatus === "--") {
      return;
    }
    const updateData = {
      document_status: updateStatus === "submitted" ? true : false,
    };
    await axiosInstance
      .patch(`/crm/appointment/${appointmentId}/`, updateData)
      .then((res) => {
        notifyToast.success("Success", "document status updated successfully!");
        getAppointments();
      })
      .catch((error) => {
        notifyToast.error("Error", error);
      });
  };

  const handleChange = (e) => {
    setDocumentStatus(e.target.value);
    setAppointmentDocumentStatus(e.target.value);
  };

  const menuIteamStyle = {
    color: "#000",
    fontFamily: "Proxima Nova",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "0.24px",
    textTransform: "capitalize",
  };

  const MenuIteamTextStyle = {
    fontFamily: "Proxima Nova",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "175%",
  };

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={documentStatus}
      label="Age"
      onChange={handleChange}
      fullWidth
      sx={{
        height: "20px",
        // padding: "4px 6px",
        color: documentStatus === "submitted" ? "#009D0F" : "#6F747E",
        border: "none",
        boxShadow: "none",
        borderRadius: "40px",
        backgroundColor:
          documentStatus === "submitted"
            ? "rgba(0, 157, 15, 0.09)"
            : "rgba(111, 116, 126, 0.09)",
        ".MuiOutlinedInput-notchedOutline": { border: 0 },
        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            border: 0,
          },
        "&:focus": {
          outline: "none",
        },
      }}
    >
      {!value && (
        <MenuItem disabled value={"--"}>
          --
        </MenuItem>
      )}
      <MenuItem sx={menuIteamStyle} value={"submitted"}>
        <Typography
          sx={{
            ...MenuIteamTextStyle,
          }}
        >
          Submitted
        </Typography>
      </MenuItem>
      <MenuItem sx={menuIteamStyle} value={"no submitted"}>
        <Typography
          sx={{
            ...MenuIteamTextStyle,
          }}
        >
          Not Submitted{" "}
        </Typography>
      </MenuItem>
    </Select>
  );
};

const GenericText = ({ variant = "body1", text, css = "", addCss = {} }) => {
  const typoStyles = {
    color: "var(--text-dark, #000)",
    textAlign: "center",
    fontFamily: "Proxima Nova",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "0.26px",
    textTransform: "capitalize",
  };
  return (
    <Typography
      variant={variant}
      sx={css ? { ...css } : { ...typoStyles, ...addCss }}
    >
      {text}
    </Typography>
  );
};

const Appointment = () => {
  const [isLoading, setisLoading] = useState(false);
  // getting auth object from redux store
  const [searchParams, setSearchParams] = useSearchParams();
  const auth = useSelector((state) => state.auth);
  const [AppointmentData, setAppointmentData] = useState([]);
  const [appointmentDate, setAppointmentDate] = useState("");
  const [blockDate, setBlockDate] = useState("");
  const [driver, setDriver] = useState("");
  const permission = usePermission();

  const [muiltipleBlockDates, setMultipleBlockDates] = useState({
    to: "",
    from: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const tab = searchParams.get("tab");
  const [accountStatus, setAccountStatus] = useState(tab || "alloted");

  const [operator, setOpertaor] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // control mui pagination

  const page = parseInt(searchParams.get("page"), 0) || 0;
  const limit = parseInt(searchParams.get("limit"), 10) || 15;

  const handlePaginationModel = (value, setPaginationModel) => {
    // Update the search parameters
    searchParams.set("page", value?.page || 0);
    searchParams.set("limit", value?.pageSize || 15);

    // Set the new search parameters with the replace option
    setSearchParams(searchParams, { replace: true });

    setPaginationModel((prev) => ({ ...prev, ...value }));
  };

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: limit || 15,
    page: page || 0,
  });

  // control mui pagination
  const [blockDatespaginationModel, setBlockDatesPaginationModel] =
    React.useState({
      pageSize: limit || 15,
      page: page || 0,
    });

  const [showApplicationFilter, setShowApplicationFilter] = useState({
    right: false,
  });

  // appointment status for action modal
  const [status, setStatus] = useState("");
  const [driverType, setDriverType] = useState("");
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  const [joinedonDate, setJoinedOnDate] = useState({
    startDate: "",
    endDate: "",
  });

  const [reseduleDetails, setReseduleDetails] = useState({
    driverName: "",
    driverNumber: "",
    id: "",
  });

  const [gender, setGender] = useState("");
  const [appointmentStatus, setAppointmentStatus] = useState("");
  const [statusUpdate, setStatusUpdate] = useState("");
  const [showRescheduleAppointment, setShowRescheduleAppointment] =
    useState(false);
  const [joinedon, setJoinedOn] = useState("");

  const navigate = useNavigate();
  const [showBlockDates, setShowBlockDates] = useState();

  // Pagination state
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");

  // confiorm model state
  const [openDeleteModel, setDeleteModel] = useState("");
  const [block, setBlock] = useState("");

  const [blockMessage, setBlockMessage] = useState("");
  const [blockDates, setBlockDates] = useState({
    data: [],
    count: 0,
  });

  const [selectionModel, setSelectionModel] = React.useState([]);

  //Search Debounce
  const searchDebounce = useDebounce(search, 500);

  const [trainingCenter, setTrainingCenter] = useState([]);

  const [slotOptions, setSlotOptions] = useState([]);

  const [tranningDetails, setTrainingDetails] = useState({
    training_center_detail_id: "",
    appintment_detail_id: "",
    reporting_time: "",
    training_center_address: "",
    slot_key: "",
  });

  const handleTrainingCenterAndSlotOptions = (value, tranningCenter) => {
    let trainingCenterObject = tranningCenter.filter((res) => res.id === value);
    if (trainingCenterObject.length > 0) {
      trainingCenterObject = trainingCenterObject[0];
      const slot_name_and_default_capacity = Object.entries(
        trainingCenterObject?.metadata?.slot_name_and_default_capacity ?? {}
      );

      if (slot_name_and_default_capacity.length > 0) {
        const slotoptions = slot_name_and_default_capacity.reduce(
          (acc, [key, value]) => {
            const obj = {
              slot_key: key,
              render_name: value["render_name"],
              render_time: value["render_time"],
            };

            acc.push(obj);

            return acc; // Make sure to return the accumulator
          },
          []
        );

        setSlotOptions(slotoptions);
      }
    }

    return trainingCenterObject;
  };

  const handleTraningCenter = (e) => {
    const { value } = e.target;
    const trainingCenterObject = handleTrainingCenterAndSlotOptions(
      value,
      trainingCenter
    );
    setTrainingDetails((prev) => ({
      ...prev,
      training_center_detail_id: trainingCenterObject?.id ?? "",
      training_center_address:
        trainingCenterObject?.training_center_address ?? "",
    }));
  };

  const handleSlotOptions = (e) => {
    const { value } = e.target;

    const renderTime = slotOptions.find((res) => res.slot_key === value);

    setTrainingDetails((prev) => ({
      ...prev,
      slot_key: value,
      reporting_time: renderTime?.render_time,
    }));
  };

  const handleSelectionModelChange = (newSelectionModel) => {
    setSelectionModel(newSelectionModel);
  };

  // mui toggleDrawer funtion for side panel
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowApplicationFilter({ ...showApplicationFilter, [anchor]: open });
  };

  const handleAccountStatus = (status) => {
    setQuery("tab", status, setSearchParams);
    setQuery("page", 0, setSearchParams);
    setQuery("limit", 15, setSearchParams);
    setAccountStatus(status);
  };

  // Fetch operators from the API and update the state
  const getOperator = async () => {
    setisLoading(true);
    let url = "/driver/operator/";
    try {
      await axiosInstance.get(url).then((res) => {
        setOpertaor(res?.data?.data ? [...res.data.data] : []);
      });
    } catch (error) {
      console.log("Error while fetching operator", error);
    } finally {
      setisLoading(false);
    }
  };

  const handleBlockDatesClick = () => {
    if (blockDate) {
      setBlockDate("");
    }

    if (muiltipleBlockDates.to || muiltipleBlockDates.from) {
      setMultipleBlockDates({
        from: "",
        to: "",
      });
    }

    setTrainingDetails({
      training_center_detail_id: "",
      appintment_detail_id: "",
      reporting_time: "",
      training_center_address: "",
    });
    setShowBlockDates((prev) => !prev);
  };

  const handleRescheduleAppointmentClick = () => {
    setShowRescheduleAppointment((prev) => !prev);
    setDate("");

    setTrainingDetails((prev) => ({
      ...prev,
      training_center_detail_id: "",
      slot_key: "",
    }));
  };

  const deleteDriverByID = async () => {
    if (!reseduleDetails?.driver_account_id) {
      return;
    }
    await axiosInstance
      .delete(`/crm/driver/${reseduleDetails.driver_account_id}/`)
      .then((_) => {
        notifyToast.success("Success", "Driver Deleted Successfully!");
        getAppointments();
      })
      .catch((error) => {
        notifyToast.error("Error", error);
      });
  };

  const updateDriverByID = async () => {
    const showMeassage =
      reseduleDetails.account_status === "INACTIVE" ||
      reseduleDetails.account_status === "ACTIVE"
        ? "BLOCKED"
        : "ACTIVE";

    const updateData = {
      account_status: showMeassage,
    };
    await axiosInstance
      .patch(`/crm/driver/${reseduleDetails.driver_account_id}/`, updateData)
      .then((_) => {
        notifyToast.success(
          "Success",
          `Driver ${
            showMeassage === "BLOCKED" ? "Block" : "UnBlock"
          }  Successfully!`
        );
        getAppointments();
      })
      .catch((error) => {
        notifyToast.error("Error", error);
      });
  };

  // Funtion to Navigate
  const handleNavigate = (driverId) => {
    if (driverId) {
      navigate(`/driver/${driverId}`);
    }
  };

  const checkQuery = () => {
    // checking for query
    let query = [];

    if (searchDebounce && search) {
      query.push(`search=${searchDebounce}`);
    }

    if (date.startDate && date.endDate) {
      let startDate = `${date.startDate.year()}-${date.startDate.month()}-${date.startDate.date()}`;
      let endDate = `${date.endDate.year()}-${date.endDate.month()}-${date.endDate.date()}`;
      query.push(
        `appointment_date__gte=${startDate}&appointemnt_date__lte=${endDate}`
      );
    }

    if (statusUpdate) {
      query.push(`status_update=${statusUpdate}`);
    }

    if (accountStatus) {
      query.push(`appointment_status=${accountStatus}`);
    }

    if (appointmentDate) {
      query.push(`appointment_date=${appointmentDate}`);
    }

    if (!accountStatus && appointmentStatus) {
      query.push(`appointment_status=${appointmentStatus}`);
    }

    if (driverType) {
      query.push(`driver_type=${driverType}`);
    }

    if (joinedon) {
      query.push(`joined_on=${joinedon}`);
    }

    if (gender) {
      query.push(`gender=${gender}`);
    }

    if (driver) {
      query.push(`driver_status=${driver}`);
    }

    let val = query.length > 1 ? query.join("&") : query.join("");

    return val;
  };

  function getAppointments() {
    setisLoading(true);
    let querys = checkQuery();

    // Define the API endpoint URL
    const apiUrl = `/crm/appointment/?page=${paginationModel.page + 1}&limit=${
      paginationModel?.pageSize ?? 15
    }&${querys}`;

    // Make a GET request to the API
    axiosInstance
      .get(apiUrl)
      .then((data) => {
        // Set the fetched data in the state
        setTotalCount(data?.data?.data?.total_count ?? 0);
        setAppointmentData(data?.data?.data?.records ?? []);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        notifyToast.error("Error", error);
      })
      .finally(() => {
        setisLoading(false);
      });
  }

  // Function To Fetch Training Center
  const getTrainingCenter = async () => {
    setisLoading(true);
    let url = "/crm/training-center/";

    try {
      await axiosInstance.get(url).then((res) => {
        setTrainingCenter(res?.data?.data?.records ?? []);
        setTrainingDetails((prev) => ({
          ...prev,
          reporting_time: res?.data?.data?.records[0]?.reporting_time ?? "",
        }));
      });
    } catch (error) {
      console.log("error", error);
    } finally {
      setisLoading(false);
    }
  };

  // Function To Fetch Block dates
  const getBlockDates = async () => {
    setisLoading(true);
    let url = "/crm/get-block-date/";

    try {
      let response = await axiosInstance.get(url);

      if (response) {
        setBlockDates({
          data: response?.data?.data?.records ?? [],
          count: response?.data?.data?.total_count ?? 0,
        });

        setTotalCount(response?.data?.data?.total_count ?? 0);
      }
    } catch (error) {
      notifyToast.error("Error", error);
    } finally {
      setisLoading(false);
    }
  };

  const unBlockDate = async (data) => {
    if (data && data.length === 0) return;
    const url = `/crm/unblock-date/`;

    const updateData = {
      id_arr: data,
    };

    setSelectionModel([]);
    getBlockDates();

    try {
      let response = axiosInstance.patch(url, updateData);

      if (response) {
        getBlockDates();
        notifyToast.success("Success", "Dates successfully unblocked!");
      }
    } catch (error) {
      notifyToast.error("Error", error);
    }
  };

  // DataGrid columns
  const columns = [
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 0.7,
      renderCell: (params) => {
        if (!params.row.phone) {
          return "--";
        }
        let phoneNumber = params.row.phone;
        let number = phoneNumber.slice(0, 3) + " " + phoneNumber.slice(3);
        return (
          <Button
            variant="text"
            onClick={(e) => {
              e.stopPropagation();
              handleNavigate(params?.row?.driver_account_id);
            }}
          >
            <Typography
              sx={{
                color: "#043A87",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "0.26px",
                textTransform: "capitalize",
              }}
            >
              {number}
            </Typography>
          </Button>
        );
      },
    },
    {
      field: "full_name",
      headerName: "Driver Name",
      flex: 0.7,
      renderCell: (params) => {
        if (!params.row.full_name) {
          return "--";
        }

        return (
          <BootstrapTooltip title={params.row.full_name}>
            <Button
              variant="text"
              sx={{
                color: "#043A87",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "0.26px",
                textTransform: "capitalize",
              }}
              onClick={() => handleNavigate(params?.row?.driver_account_id)}
            >
              {params.row.full_name}
            </Button>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "nick_name",
      headerName: "Driver nick name",
      flex: 0.7,
      renderCell: (params) => {
        if (!params.row.nick_name) {
          return "--";
        }

        return (
          <BootstrapTooltip title={params.row.full_name}>
            <Button
              variant="text"
              sx={{
                color: "#043A87",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "0.26px",
                textTransform: "capitalize",
              }}
              onClick={() => handleNavigate(params?.row?.driver_account_id)}
            >
              {params.row.full_name}
            </Button>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "appointment_status",
      headerName: "Appointment status",
      flex: 0.5,
      renderCell: (param) => {
        if (!param.row.appointment_status) {
          return "";
        }

        const getColorBasedOnStatus = (appoinstatus) => {
          switch (appoinstatus) {
            case "Requested":
              return "#F98244";
            case "Alloted":
              return "#043A87";
            case "No Request":
              return "#6F747E";
            case "No Show":
              return "#F1554C";
            default:
              // Default color if none of the specified statuses match
              return "#000000";
          }
        };

        const getBackgroundColorBasedOnStatus = (appointmentStatus) => {
          switch (appointmentStatus) {
            case "Requested":
              return "rgba(249, 130, 68, 0.09)";
            case "Alloted":
              return "rgba(4, 58, 135, 0.09)";
            case "No Request":
              return "rgba(111, 116, 126, 0.10)";
            case "No Show":
              return "rgba(241, 85, 76, 0.08)";
            default:
              // Default color if none of the specified statuses match
              return "#000000";
          }
        };

        let appointmentStatus =
          param?.value
            ?.replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase())
            .replace(/\s/g, " ") ?? param.value;

        return (
          <>
            <Typography
              sx={{
                color: getColorBasedOnStatus(appointmentStatus),
                textAlign: "center",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "0.26px",
                textTransform: "capitalize",
                borderRadius: "40px",
                padding: "4px 6px",
                background: getBackgroundColorBasedOnStatus(appointmentStatus),
              }}
            >
              {param.row.appointment_status === ""
                ? "pending"
                : removeSlugFromString(param?.row?.appointment_status ?? "")}
            </Typography>
          </>
        );
      },
    },
    {
      field: "date",
      headerName: "Appointment date",
      flex: 0.5,
    },
    {
      field: "current_step_status",
      headerName: "Status Update",
      flex: 0.5,
      renderCell: (params) => {
        if (!params.row.current_step_status) {
          return "--";
        }

        return (
          <>
            <Typography
              sx={{
                color: "#F98244",
                fontFamily: "Proxima Nova",
                fontSize: "12px",
                fontStyle: "normal",
                letterSpacing: "0.26px",
                textTransform: "capitalize",
              }}
            >
              {params.row.current_step_status.replace(/_/g, " ")}
            </Typography>
          </>
        );
      },
    },

    {
      field: "document_status",
      headerName: "Document Status",
      flex: 0.6,
      renderCell: (params) => {
        return (
          <>
            {/* <Typography
              sx={{
                fontFamily: "Proxima Nova",
                fontSize: "normal",
                fontStyle: "normal",
                fontWeight: "400",
                letterSpacing: "0.26px",
                textTransform: "capitalize",
                color: "#000",
              }}
            >
              {params.row.document_status}
            </Typography> */}
            <AppointmentDocumentStatus
              appointmentId={params.row.id}
              value={params.value}
              authObj={auth}
              getAppointments={getAppointments}
            />
          </>
        );
      },
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 0.5,

      renderCell: (params) => {
        if (!params.row.gender) {
          return "--";
        }

        return (
          <Typography
            sx={{
              fontFamily: "Proxima Nova",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: "400",
              letterSpacing: "0.26px",
              textTransform: "capitalize",
              color: "#000",
            }}
          >
            {params?.row?.gender ?? "--"}
          </Typography>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.5,
      renderCell: (params) => {
        if (!params.row.email) {
          return "--";
        }

        return (
          <>
            <BootstrapTooltip title={params.row.email}>
              <Typography
                sx={{
                  display: "-webkit-box",
                  width: "160px",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                  overflow: "hidden",
                  color: "var(--text-dark, #000)",
                  textOverflow: "ellipsis",
                  fontFamily: "Proxima Nova",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  letterSpacing: "0.26px",
                  textTransform: "lowercase",
                }}
              >
                {params.row.email}
              </Typography>
            </BootstrapTooltip>
          </>
        );
      },
    },
    {
      field: "",
      headerName: "",
      flex: 0.1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) => {
                setReseduleDetails({
                  id: params?.row?.id ?? "",
                  driverName: params?.row?.full_name ?? "",
                  driverNumber: params?.row?.phone ?? "",
                  appointmentStatus: params?.row?.appointment_status ?? "",
                  appointmentDate: params?.row?.date ?? "",
                  driver_account_id: params.row?.driver_account_id ?? "",
                  account_status: params?.row?.accountStatus,
                });
                setBlockMessage(
                  params?.row.account_status === ("INACTIVE" || "ACTIVE" || "")
                    ? "Block"
                    : "unblock"
                );
                handleClick(e);
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MoreVertIcon
                sx={{
                  color: "000",
                }}
              />
            </IconButton>
            <AppointmentAction
              handleClose={handleClose}
              permission={permission}
              anchorEl={anchorEl}
              setBlock={setBlock}
              params={params}
              status={reseduleDetails}
              setDeleteModel={setDeleteModel}
              handleRescheduleAppointmentClick={
                handleRescheduleAppointmentClick
              }
            />
          </>
        );
      },
    },
  ];

  // dataGrid columns
  const blockDatesColumns = useMemo(
    () => [
      {
        field: "date",
        headerName: "Block Date",
        flex: 0.7,
        renderCell: (params) => {
          if (!params?.value) return;

          return (
            <GenericText
              text={formatDate(params.value)}
              addCss={{ marginLeft: "5px" }}
            />
          );
        },
      },
      {
        field: "training_center_name",
        headerName: "Traning Center Name",
        flex: 0.7,
        renderCell: (params) => {
          if (!params?.value) return;

          return <GenericText text={params.value} />;
        },
      },
      {
        field: "training_center_address",
        headerName: "Traning Center Address",
        flex: 3.5,
        renderCell: (params) => {
          if (!params?.value) return;

          return (
            <GenericText
              text={params.value}
              addCss={{ width: "650px", overflow: "hidden" }}
            />
          );
        },
      },
    ],
    []
  );

  // config
  const allowed_sub_sections = permission?.SUB_SECTIONS ?? [
    "appointments",
    "alloted request",
    "no_request",
    "block_dates",
  ];

  const sub_section_to_label = {
    appointments: "Allotted",
    "alloted request": "Requested",
    no_request: "No Request",
    block_dates: "Block Dates",
  };

  const sub_section_to_value = {
    appointments: "alloted",
    "alloted request": "requested",
    no_request: "no_request",
    block_dates: "block dates",
  };

  const tableHeadButtonConfig = allowed_sub_sections?.map((sub_section) => {
    return {
      label: sub_section_to_label[sub_section],
      value: sub_section_to_value[sub_section],
      totalcount: totalCount,
      onClick: handleAccountStatus,
    };
  });

  const filtersOption = [
    {
      summryText: "Appointment status",
      state: appointmentStatus,
      setState: setAppointmentStatus,
      options: [
        { value: "requested", label: "Request" },
        { value: "no_show", label: "No show" },
        { value: "alloted", label: "Allotted" },
        { value: "no_request", label: "No request" },
      ],
    },
    {
      summryText: "Appointment date",
      state: appointmentDate,
      setState: setAppointmentDate,
      options: [
        { label: "Today", value: "today" },
        { label: "Tomorrow", value: "tomorrow" },
        { label: "Yesterday", value: "yesterday" },
        { label: "Last month", value: "last_month" },
        { label: "Last year", value: "last_year" },
      ],
      date: {
        customDateState: date,
        customDateHeader: "Custom",
        customDateLabel: ["From", "To"],
        customDateName: ["startDate", "endDate"],
        customeDateHandler,
        setState: setDate,
        // isoString: true,
      },
    },
    {
      summryText: "Status update",
      state: statusUpdate,
      setState: setStatusUpdate,
      options: [
        { label: "Approved", value: "COMPLETED" },
        { label: "Pending", value: "DETAILS_PENDING" },
        { label: "Document Pending", value: "DOCUMENT_PENDING" },
        { label: "Training Pending", value: "TRAINING_PENDING" },
        { label: "Approval pending", value: "APPROVAL_PENDING" },
      ],
    },
    {
      summryText: "Gender",
      state: gender,
      setState: setGender,
      options: [
        { label: "Male", value: "MALE" },
        { label: "Female", value: "FEMALE" },
      ],
    },
    {
      summryText: "Drivers",
      state: driver,
      setState: setDriver,
      options: [
        { label: "New", value: "UNVERIFIED" },
        { label: "Onboarded", value: "VERIFIED" },
        { label: "Blocked", value: "BLOCKED" },
      ],
    },
    {
      summryText: "Driver type",
      state: driverType,
      setState: setDriverType,
      options: [
        { label: "Individual", value: "INDIVIDUAL" },
        { label: "Operators", value: "VIA_OPERATOR" },
      ],
    },
    {
      summryText: "Joined On",
      state: joinedon,
      setState: setJoinedOn,
      options: [
        { label: "Yesterday", value: "yesterday" },
        { label: "Last Week", value: "last_week" },
        { label: "Last month", value: "last_month" },
        { label: "Last year", value: "last_year" },
      ],
      date: {
        customDateState: joinedonDate,
        customDateHeader: "Custom",
        customDateLabel: ["From", "To"],
        customDateName: ["startDate", "endDate"],
        customeDateHandler,
        setState: setJoinedOnDate,
        isoString: true,
      },
    },
  ];

  useEffect(() => {
    getTrainingCenter();
    getOperator();
    getBlockDates();
  }, []);

  useEffect(() => {
    if (accountStatus === "block dates") {
      getBlockDates();
      return;
    } else {
      if (selectionModel) {
        setSelectionModel([]);
      }
    }
    getAppointments();
  }, [
    paginationModel.page,
    paginationModel.pageSize,
    searchDebounce,
    accountStatus,
    appointmentStatus,
    date.startDate,
    date.endDate,
    gender,
    appointmentDate,
    statusUpdate,
    driverType,
    joinedon,
    driver,
  ]); // The empty array [] ensures that this effect runs once when the component mounts

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "17px",
          maxWidth: "1248px",
          margin: "20px",
        }}
      >
        <SearchActionComponent
          value={search}
          setState={setSearch}
          toggleDrawer={toggleDrawer}
        />
        {permission?.CREATE && (
          <SecondaryButton
            onClick={handleBlockDatesClick}
            startIcon={
              <BlockIcon
                sx={{
                  height: "16px",
                }}
              />
            }
          >
            Block dates
          </SecondaryButton>
        )}

        {permission?.CREATE && (
          <PrimaryButton
            variant="contained"
            startIcon={
              <AddIcon
                sx={{
                  height: "16px",
                }}
              />
            }
            onClick={(_) => {
              handleNavigate("new-driver");
            }}
          >
            Add Driver
          </PrimaryButton>
        )}

        <BlockDatesModel
          accountStatus={accountStatus}
          showBlockDates={showBlockDates}
          handleBlockDatesClick={handleBlockDatesClick}
          blockDate={blockDate}
          setBlockDate={setBlockDate}
          getBlockDates={getBlockDates}
          muiltipleBlockDates={muiltipleBlockDates}
          setMultipleBlockDates={setMultipleBlockDates}
          trainingCenter={trainingCenter}
          setTrainingCenter={setTrainingCenter}
          handleTraningCenter={handleTraningCenter}
          tranningDetails={tranningDetails}
          setTrainingDetails={setTrainingDetails}
        />

        <SwipeableDrawer
          open={showApplicationFilter["right"]}
          onOpen={() => toggleDrawer("right", true)}
          anchor="right"
          onClose={toggleDrawer("right", false)}
        >
          <CustomFilter
            filterOptions={filtersOption}
            toggleDrawer={() => toggleDrawer("right", false)}
          />
        </SwipeableDrawer>
      </Box>

      <Box sx={{ m: "1.5rem 1.5rem" }}>
        {filtersOption && <FilteredResults options={filtersOption} />}
        <Box
          sx={{
            mt: "15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CommonTableHead
            active={accountStatus}
            ButtonConfig={tableHeadButtonConfig}
            totalcount={totalCount}
          />

          {accountStatus === "block dates" && (
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Button
                sx={{
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  background: "#fff",
                  color: "#000",
                  border: "1px solid #929EAE",
                  cursor: "pointer",
                  "&:hover": {
                    background: "#fff",
                  },
                  "&:disabled": {
                    color: "#363636",
                    cursor: "not-allowed",
                  },
                }}
                variant={"contained"}
                disabled={selectionModel.length === 0}
                onClick={() => {
                  unBlockDate(selectionModel);
                }}
              >
                UNBLOCK DATES
              </Button>
            </Box>
          )}
        </Box>

        {accountStatus !== "block dates" ? (
          <DataTable
            ownPaginationHandler={true}
            row={AppointmentData || []}
            alternativeId="driver_account_id"
            columns={columns}
            loading={isLoading}
            paginationModel={paginationModel}
            onPaginationModelChange={(e) => {
              handlePaginationModel(e, setPaginationModel);
            }}
            totalCount={totalCount}
          />
        ) : (
          <DataTable
            ownPaginationHandler={true}
            row={blockDates?.data || []}
            // alternativeId="driver_account_id"
            columns={blockDatesColumns}
            loading={isLoading}
            paginationModel={paginationModel}
            onPaginationModelChange={(e) => {
              handlePaginationModel(e, setPaginationModel);
            }}
            totalCount={totalCount}
          />
        )}
        <RescheduleAppointmentModel
          showRescheduleAppointment={showRescheduleAppointment}
          handleRescheduleAppointmentClick={handleRescheduleAppointmentClick}
          reseduleDetails={reseduleDetails}
          setTrainingDetails={setTrainingDetails}
          handleTraningCenter={handleTraningCenter}
          trainingCenter={trainingCenter}
          tranningDetails={tranningDetails}
          getAppointments={getAppointments}
          slotOptions={slotOptions}
          handleSlotOptions={handleSlotOptions}
        />
      </Box>

      <CustomConfirmationDialog
        open={openDeleteModel}
        setOpen={setDeleteModel}
        deleteById={deleteDriverByID}
        status="delete"
        message={`Are you sure you want to delete ${reseduleDetails.driverName}?`}
      />

      <CustomConfirmationDialog
        open={block}
        setOpen={setBlock}
        blockById={updateDriverByID}
        status="block"
        message={`Are you sure you want to ${blockMessage} ${reseduleDetails.driverName}`}
      />
    </>
  );
};

export default Appointment;
