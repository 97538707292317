import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  TextareaAutosize,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import FileUploadInput from "./FileUploadInput";
import axiosInstance from "../utils/axiosConfig";
import { object } from "yup";
import filterEmptyPropertiesRecursively from "../utils/filterEmptyPropertiesRecursively";
import ClearIcon from "@mui/icons-material/Clear";
import usePermission from "../hooks/usePermission";
import { useLocation } from "react-router-dom";
import { notifyToast } from "../utils/notify";

const getOrderForStatus = (status, options) => {
  // Check if status is provided and matches a valid option value
  if (status) {
    // Find the option with a matching value and return its order
    const matchedOption = options.find((option) => option.value === status);

    if (matchedOption) {
      console.log("Change status: order", "hit");
      return matchedOption.order;
    }
  }
  return null;
};

const intialoptions = [
  {
    label: "initiated",
    value: "INITIATED",
    order: 1,
  },
  {
    label: "under review",
    value: "UNDER_REVIEW",
    order: 2,
  },
  {
    label: "resolved",
    value: "COMPLETED",
    order: 3,
  },
  {
    label: "reopened",
    value: "REOPENED",
    order: 0,
  },
];

const theme = createTheme({
  overrides: {
    MuiModal: {
      root: {
        backgroundColor: "rgba(0,0,0,0.1)", // or 'initial'
      },
    },
  },
});

const intialuploadFile = {
  internalNote: {
    imgurl: "",
    id: "",
    file: "",
  },
  messageToUser: {
    imgurl: "",
    id: "",
    file: "",
  },
};

const initialChangeStatus = {
  ticketStatus: "",
  internalNote: "",
  messageToUser: "",
};

const ChangeStatusBox = ({
  status,
  DId,
  role,
  ticketId,
  getTickets,
  chartoptions = [],
  data = [],
}) => {
  const [changeTicketStatus, setChangeTicketStatus] = useState(status ?? "");
  const [changeStatus, setChangeStatus] = useState(initialChangeStatus);
  const permission = usePermission();
  const location = useLocation();

  const trackUploadRef = useRef("");

  const [open, setOpen] = React.useState(false);

  const [uploadFile, setUploadFile] = useState(intialuploadFile);

  const uploadFileToApi = async (file, name) => {
    const apiUrl = "/utils/file/";
    const formData = new FormData();

    const options = {
      headers: {
        "Content-Type": "multipart/form-data", // This is important for form data
      },
    };
    formData.append("file", file);
    formData.append("folder", "public/");

    try {
      await axiosInstance.post(apiUrl, formData, options).then((res) => {
        setUploadFile((prevstate) => ({
          ...prevstate,
          [name]: { ...prevstate[name], id: res.data.data.id },
        }));
      });
    } catch (error) {
      notifyToast.error("Error", error);
    }
  };

  const order = getOrderForStatus(status, intialoptions);

  const handleClick = () => {};

  const filteredOptions = intialoptions
    .filter((res) => res.order >= order)
    .map((option, index) => (
      <MenuItem key={`${option.label}-${index}`} value={option.value}>
        {option.label}
      </MenuItem>
    ));

  const updateStatus = async () => {
    const url = `/crm/${role}/ticket/${ticketId}/`;

    const updateData = {
      remarks: changeStatus.messageToUser,
      internal_notes: changeStatus.internalNote,
      status: changeTicketStatus,
    };

    if (status !== changeTicketStatus) {
      updateData["status"] = changeTicketStatus;
    } else {
      if (chartoptions.length > 0) {
        updateData["ticket_status_tracker_id"] =
          chartoptions[chartoptions.length - 1]?.id;
      }
    }

    const assets = [];

    if (uploadFile.internalNote.id) {
      assets.push({
        id: uploadFile.internalNote.id,
        type: "INTERNAL",
      });
    }

    if (uploadFile.messageToUser.id) {
      assets.push({
        id: uploadFile.messageToUser.id,
        type: "APP",
      });
    }

    if (assets.length > 0) {
      updateData["assets"] = assets;
    }

    const check = filterEmptyPropertiesRecursively(updateData);

    if (Object.entries(check).length <= 1) {
      notifyToast.error("Error", "please fill the details!");
      return;
    }

    if (!check?.remarks) {
      notifyToast.error(
        "Error",
        "Please fill in the Message To User field. It is required!",
        {
          autoClose: 3000,
        }
      );
      return;
    }

    try {
      await axiosInstance.patch(url, check);
      setUploadFile(intialuploadFile);
      setChangeStatus(initialChangeStatus);
      notifyToast.success("Success", "Status Update successfully!");
      getTickets();
    } catch (error) {
      notifyToast.error("Error", error);
    }
  };

  const handleStatus = (event) => {
    const { name, value } = event.target;
    setChangeStatus((prev) => ({ ...prev, [name]: value }));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!DId) {
      setUploadFile({
        internalNote: {
          imgurl: "",
          id: "",
          file: "",
        },
        messageToUser: {
          imgurl: "",
          id: "",
          file: "",
        },
      });

      setChangeStatus({
        ticketStatus: "",
        internalNote: "",
        messageToUser: "",
      });
    }
  }, [DId]);

  useEffect(() => {
    setChangeTicketStatus(status);
  }, [status]);

  useEffect(() => {
    if (changeTicketStatus === status) {
      if (data) {
        const driverData = data[data.length - 1];
        const admin_remarks = driverData?.admin_remarks;
        if (admin_remarks) {
          setChangeStatus((prev) => ({
            ...prev,
            messageToUser: admin_remarks,
          }));
        }
      }
    } else {
      setChangeStatus((prev) => ({
        ...prev,
        messageToUser: "",
      }));
    }
  }, [changeTicketStatus, status]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "auto",
        padding: "20px 20px",
        border: "0.697px solid #E2E4E8",
        m: "15px auto",
        borderRadius: "6.968px",
        flexDirection: "column",
        boxSizing: "border-box",
      }}
    >
      <Typography>{`Change Status(Optional)`}</Typography>
      {intialoptions.length > 0 && (
        <Select
          disabled={!permission?.UPDATE}
          value={changeTicketStatus}
          sx={{
            height: "30px",
            width: "35%",
            backgroundColor: "rgba(4, 58, 135, 0.10)",
            borderRadius: "66.536px",
            boxSizing: "border-box",
            mt: "13px",
          }}
          onChange={(e) => {
            const selectedValue = e.target.value;
            setChangeTicketStatus(selectedValue);
          }}
        >
          {filteredOptions}
        </Select>
      )}
      {status !== changeTicketStatus ? (
        <p>This Will Create New Activity log In chart</p>
      ) : (
        <p>This Will Update the latest log</p>
      )}
      <ThemeProvider theme={theme}>
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              width: "100%",
              minHeight: "100vh",
              borderRadius: "20px",
              background: "rgba(0,0,0,0.2)",
              position: "relative",
              boxSizing: "border-box",
              padding: "20px",
              zIndex: "9999",
            }}
          >
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Button
                sx={{
                  color: "#000",
                  background: "#fff",
                  borderRadius: "5px",
                  "&:hover": {
                    background: "#fff",
                  },
                }}
                onClick={() => {
                  handleClose();
                  if (trackUploadRef.current === "APP") {
                    setUploadFile((prevstate) => ({
                      ...prevstate,
                      messageToUser: intialuploadFile.messageToUser,
                    }));
                  } else {
                    setUploadFile((prevstate) => ({
                      ...prevstate,
                      internalNote: intialuploadFile.internalNote,
                    }));
                  }
                }}
              >
                Remove Image
              </Button>
              <IconButton
                onClick={() => {
                  handleClose();
                }}
              >
                <ClearIcon
                  sx={{
                    fill: "#fff",
                  }}
                />
              </IconButton>
            </Box>
            <Box
              width={"75%"}
              margin={"auto"}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <img
                src={
                  trackUploadRef.current === "APP"
                    ? uploadFile.messageToUser.imgurl
                    : uploadFile.internalNote.imgurl
                }
                alt=""
                style={{ objectFit: "contain" }}
                height={"100%"}
                width={"100%"}
              />
            </Box>
          </Box>
        </Modal>
      </ThemeProvider>
      <Stack gap={"24px"}>
        <Box
          sx={{
            paddingTop: "32px",
            width: "100%",
          }}
        >
          <Stack
            width={"100%"}
            direction={"row"}
            alignItems={"flex-end"}
            justifyContent={"space-between"}
            mb="10px"
          >
            <Typography>Internal Notes</Typography>
            <Button
              variant="text"
              endIcon={<InsertLinkIcon />}
              onClick={() => {
                if (!uploadFile?.internalNote?.imgurl) {
                  const idExist = document.getElementById(
                    "upload-file-internal-note"
                  );

                  if (idExist) {
                    idExist.click();
                  }
                } else {
                  handleOpen();
                  trackUploadRef.current = "INTERNAL";
                }
              }}
              sx={{
                color: "#000",
                margin: "0",
                padding: "0",
              }}
            >
              {uploadFile.internalNote.imgurl ? "View Image" : "Attach"}
            </Button>
          </Stack>

          <TextareaAutosize
            value={changeStatus?.internalNote ?? ""}
            name="internalNote"
            onChange={handleStatus}
            style={{
              boxSizing: "border-box",
              width: "100%",
              height: "97px",
              padding: "10px",
              backgroundColor: "#FDFFA8",
              borderRadius: "4px",
              resize: "none",
            }}
            placeholder="Notes for Internal Team"
          />
        </Box>

        <Box
          sx={{
            paddingTop: "32px",
            width: "100%",
          }}
        >
          <Stack
            width={"100%"}
            direction={"row"}
            boxSizing={"border-box"}
            alignItems={"flex-end"}
            justifyContent={"space-between"}
            mb="10px"
          >
            <Typography>
              Message To
              {` ${
                location?.pathname === "/driver-tickets" ? "Driver" : "User"
              }`}
            </Typography>
            <Button
              variant="text"
              endIcon={<InsertLinkIcon />}
              onClick={() => {
                if (!uploadFile?.messageToUser?.imgurl) {
                  const idExist = document.getElementById(
                    "upload-file-message"
                  );

                  if (idExist) {
                    idExist.click();
                  }
                } else {
                  handleOpen();
                  trackUploadRef.current = "APP";
                }
              }}
              sx={{
                color: "#000",
                margin: "0",
                padding: "0",
              }}
            >
              {uploadFile.messageToUser.imgurl ? "View Image" : "Attach"}
            </Button>
          </Stack>

          <TextareaAutosize
            value={changeStatus?.messageToUser}
            name={"messageToUser"}
            onChange={handleStatus}
            style={{
              boxSizing: "border-box",
              width: "100%",
              height: "97px",
              padding: "10px",
              borderRadius: "4px",
              resize: "none",
            }}
            placeholder="Visiable to User on App"
          />
        </Box>
      </Stack>
      <FileUploadInput
        id={"upload-file-message"}
        onFileChange={(file) => {
          if (!file) return;
          setUploadFile((prevstate) => ({
            ...prevstate,
            messageToUser: {
              ...prevstate.messageToUser,
              file,
              imgurl: URL.createObjectURL(file),
            },
          }));
          uploadFileToApi(file, "messageToUser");
        }}
      />
      <FileUploadInput
        id={"upload-file-internal-note"}
        onFileChange={(file) => {
          if (!file) return;
          setUploadFile((prevstate) => ({
            ...prevstate,
            internalNote: {
              ...prevstate.messageToUser,
              file,
              imgurl: URL.createObjectURL(file),
            },
          }));

          uploadFileToApi(file, "internalNote");
        }}
      />
      <Box
        sx={{
          padding: "0 24px",
          display: "flex",
          justifyContent: "flex-end",
          gap: "12px",
          mt: "32px",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            borderRadius: "10px",
            border: " 1px solid #B3B3B3",
            width: "144px",
            boxSizing: "border-box",
            height: "50px",
          }}
          disabled
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!permission?.UPDATE}
          onClick={updateStatus}
          sx={{
            borderRadius: "10px",
            border: " 1px solid #B3B3B3",
            width: "144px",
            height: "50px",
            backgroundColor: "#053B87",
            boxSizing: "border-box",
          }}
        >
          Done
        </Button>
      </Box>
    </Box>
  );
};

export default ChangeStatusBox;
