import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import RideStatus from "./RideStatus";
import DetailsSidePanel from "./DetailsSidePanel";
import useDebounce from "../hooks/useDebounce";
import formatNumberShort from "../utils/formatNumberShort";
import axiosInstance from "../utils/axiosConfig";
import { convertTo24HourFormat } from "../utils/convertTimestampTo12HourFormat";
import { truncateWithEllipsis } from "../utils/removeSlugFromString";
import SearchActionComponent from "./reusableComponents/SearchActionComponent";
import SwipeableDrawerComponent from "./SwipeableDrawerComponent ";
import { BootstrapTooltip } from "./DetailsSidePanelChart";
import usePermission from "../hooks/usePermission";
import formatDate from "../utils/formateDate";
import AddColumn from "./AddColumn";
import {
  customeDateHandler,
  customeTimeHandler,
} from "../const/commonfilterobject";
import CustomFilter, {
  SortPanel,
} from "./reusableComponents/Filter/CustomFilter";
import FilteredResults from "./FilteredResults";
import { showErrorToast } from "../utils/toastUtils";
import { removeQuery } from "../utils/queryHelpers";
import DataTable from "./DataTable";
import { bookingIdObj, commonSortOptions } from "../const/sort";
import useQueryParams from "../hooks/useQueryParams ";
import dayjs from "dayjs";
import CommonTableHead from "./CommonTableHead";
import { notifyToast } from "../utils/notify";

const TData = {
  columns: [
    { key: "cancelled_by", name: "Cancelled By" },
    {
      key: "ride_cancellation_reason",
      name: "RIDE Cancellation Reason",
    },
    {
      key: "unflagged_by",
      name: "Unflagged By",
    },
    {
      key: "unflagged_reason",
      name: "Unflagged Reason",
    },
  ],
};

const StatusIndicator = ({ index, text, fill }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      gap: "12px",
    }}
  >
    <svg
      style={{
        borderRadius: "50%",
        ...(index === 0 && {
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        }),
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle cx="10" cy="10" r="10" fill={fill} />
    </svg>

    <Typography>{text}</Typography>
  </Box>
);

// main component
const DriverTrips = () => {
  const [isLoading, setisLoading] = useState(false);

  const location = useLocation();

  const [trips, setTrips] = useState([]);

  const { searchParams, setSearchParams, setQuery, getQuery } =
    useQueryParams();
  const rangeParam = searchParams.get("range");
  const [range, setRange] = useState(
    rangeParam || location?.state?.range || ""
  );
  const permissions = usePermission();
  // Loader state
  const [loader, setLoader] = useState(false);
  const { pathname } = useLocation();
  const [customerId, setCustomerId] = useState(location?.state?.rider_id || "");

  const toBoolean = (value) => {
    return value === "true";
  };

  const drawer = toBoolean(searchParams.get("drawer"));
  const id = searchParams.get("id");
  const [showDriverDetails, setDriverDetails] = useState({
    right: drawer || false,
  });
  const [driverId, setDriverId] = useState(id || "");
  const [showFilter, setShowFilter] = useState({ right: false });
  const [showCustomerFilter, setCustomerFilter] = useState({ right: false });
  const [carType, setCarType] = useState(getQuery("car_type") ?? "");
  const rideStatusParam = searchParams.get("ride_status");
  const [rideStatus, setRideStatus] = useState(
    rideStatusParam ?? location?.state?.status ?? ""
  );

  const cancelledByParam = searchParams.get("cancelled_by");

  const [cancelledBy, setCancelledBy] = useState(
    cancelledByParam || location?.state?.cancelledBy || ""
  );
  const [bookingDay, setBookingDay] = useState(getQuery("booking_day") ?? "");
  const [date, setDate] = useState({
    startDate: getQuery("booking_date_gte")
      ? dayjs(getQuery("booking_date_gte"))
      : null,
    endDate: getQuery("booking_date_lte")
      ? dayjs(getQuery("booking_date_lte"))
      : null,
  });

  const [tripsFilter, setTripsFilter] = useState("");

  // dataGrid columns
  const columns = [
    {
      field: "",
      headerName: "",
      flex: 0.1,
      renderCell: (params) => <RideStatus {...params} />,
    },
    {
      field: "booking_id",
      headerName: "Booking ID",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Button
              id={params.id}
              sx={{
                color: "#043A87",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                letterSpacing: "0.26px",
                textTransform: "capitalize",
                border: "none",
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (!params?.id) {
                  return;
                }
                const handleEvent = toggleDrawer("right", true);
                setDriverId(params?.id ?? "");
                setIdQueryParam(params?.id);
                handleEvent(e);
              }}
            >
              {params.row.booking_id}
            </Button>
          </>
        );
      },
    },
    {
      field: "Phone No",
      headerName: "Phone NO",
      flex: 1,
      valueGetter: (params) => {
        if (!params?.row?.driver_details?.phone) {
          return "--";
        }

        let phoneNumber = params.row.driver_details.phone;
        let number = phoneNumber.slice(0, 3) + " " + phoneNumber.slice(3);
        return number;
      },

      renderCell: (params) => {
        if (!params?.row?.driver_details?.phone) {
          return "--";
        }

        let phoneNumber = params.row.driver_details.phone;
        let number = phoneNumber.slice(0, 3) + " " + phoneNumber.slice(3);
        return (
          <>
            <Button
              id={params.id}
              sx={{
                color: "#043A87",
                textOverflow: "ellipsis",
                width: "100%",
                fontWeight: "500",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                textTransform: "capitalize",
                border: "none",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleNavigate(params?.row.driver_details?.id ?? "");
              }}
            >
              {number}
            </Button>
          </>
        );
      },
    },
    {
      field: "driver_details.full_name",
      headerName: "Driver",
      // flex: 0.6,
      renderCell: (params) => {
        if (!params?.row?.driver_details?.full_name) {
          return "--";
        }

        return (
          <>
            <BootstrapTooltip title={params?.row?.driver_details?.full_name}>
              <Button
                id={params.id}
                sx={{
                  border: "none",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: "vertical",
                }}
                onClick={() => {
                  handleNavigate(params?.row.driver_details?.id ?? "");
                }}
              >
                <Typography
                  sx={{
                    color: "#043A87",
                    fontFamily: "Proxima Nova",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "13px",
                    textTransform: "capitalize",
                    letterSpacing: "0.26px",
                    fontWeight: "500",
                  }}
                >
                  {params?.row?.driver_details?.full_name}
                </Typography>
              </Button>
            </BootstrapTooltip>
          </>
        );
      },
    },
    {
      field: "driver_details.nick_name",
      headerName: "Driver nick name",
      flex: 0.6,
      renderCell: (params) => {
        if (!params?.row?.driver_details?.nick_name) {
          return "--";
        }

        return (
          <>
            <BootstrapTooltip title={params?.row?.driver_details?.nick_name}>
              <Button
                id={params.id}
                sx={{
                  border: "none",
                  display: "flex",
                  justifyContent: "flex-start",
                  overflow: "hidden",
                  width: "100%",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: "vertical",
                }}
                onClick={() => {
                  handleNavigate(params?.row.driver_details?.id ?? "");
                }}
              >
                <Typography
                  sx={{
                    color: "#043A87",
                    fontFamily: "Proxima Nova",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "13px",
                    textTransform: "capitalize",
                    letterSpacing: "0.26px",
                    fontWeight: "500",
                  }}
                >
                  {params?.row?.driver_details?.nick_name}
                </Typography>
              </Button>
            </BootstrapTooltip>
          </>
        );
      },
    },

    {
      headerName: "Customer",
      field: "rider_details.full_name",
      flex: 1,
      renderCell: (params) => {
        if (!params?.row?.rider_details?.full_name) {
          return "--";
        }

        return (
          <>
            <BootstrapTooltip title={params?.row?.rider_details?.full_name}>
              <Button
                id={params.id}
                sx={{
                  color: "#043A87",
                  fontFamily: "Proxima Nova",
                  fontSize: "13px",
                  border: "none",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  let callBack = toggleCustomerFilterDrawer("right", true);
                  setDriverId(params?.id ?? "");
                  setDrawerQueryParam(true);
                  setIdQueryParam(params?.id);
                  callBack(e);
                }}
              >
                <Typography
                  sx={{
                    color: "#043A87",
                    fontFamily: "Proxima Nova",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "13px",
                    textTransform: "capitalize",
                    letterSpacing: "0.26px",
                    fontWeight: "500",
                  }}
                >
                  {params?.row?.rider_details?.full_name}
                </Typography>
              </Button>
            </BootstrapTooltip>
          </>
        );
      },
    },
    {
      headerName: "Customer nick name",
      field: "rider_details.nick_name",
      flex: 1,
      renderCell: (params) => {
        if (!params?.row?.rider_details?.nick_name) {
          return "--";
        }

        return (
          <>
            <BootstrapTooltip title={params?.row?.rider_details?.nick_name}>
              <Button
                id={params.id}
                sx={{
                  color: "#043A87",
                  fontFamily: "Proxima Nova",
                  fontSize: "13px",
                  border: "none",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  let callBack = toggleCustomerFilterDrawer("right", true);
                  setDriverId(params?.id ?? "");
                  callBack(e);
                }}
              >
                <Typography
                  sx={{
                    color: "#043A87",
                    fontFamily: "Proxima Nova",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "13px",
                    textTransform: "capitalize",
                    letterSpacing: "0.26px",
                    fontWeight: "500",
                  }}
                >
                  {truncateWithEllipsis(
                    params?.row?.rider_details?.nick_name,
                    20
                  )}
                </Typography>
              </Button>
            </BootstrapTooltip>
          </>
        );
      },
    },
    // {
    //   field: "ride_start_time",
    //   headerName: "Time",
    //   flex: 0.7,
    //   renderCell: (params) => {
    //     if (!params.value) {
    //       return "--";
    //     }

    //     let date = new Date(params.value);
    //     const hours = date.getHours();
    //     const minutes = date.getMinutes();
    //     const ampm = hours >= 12 ? "PM" : "AM";
    //     const hours12 = hours % 12 || 12;
    //     const formattedTime = `${hours12}:${
    //       (minutes < 10 ? "0" : "") + minutes
    //     } ${ampm}`;

    //     return (
    //       <Typography
    //         sx={{
    //           fontFamily: "Proxima Nova",
    //           fontSize: "13px",
    //           fontStyle: "normal",
    //           fontWeight: "400",
    //           lineHeight: "normal",
    //           letterSpacing: "0.26px",
    //         }}
    //       >
    //         {formattedTime}
    //       </Typography>
    //     );
    //   },
    // },
    // {
    //   field: "pickup_location.address_text",
    //   headerName: "From",
    //   flex: 1,
    //   renderCell: (param) => {
    //     // here we are taking row value from  params object
    //     if (!param.row.pickup_location.address_text) {
    //       return "--";
    //     }

    //     let pickeUpLoaction = param.row.pickup_location.address_text.replace(
    //       /,/g,
    //       " "
    //     );

    //     return (
    //       <Typography
    //         sx={{
    //           textOverflow: "ellipsis",
    //            width: "100%" ,
    //           height: "31px",
    //           overflow: "hidden",
    //           fontSize: "12px",
    //           fontStyle: "normal",
    //           fontFamily: "Proxima Nova",
    //           whiteSpace: "nowrap",
    //           fontWeight: "400",
    //           letterSpacing: "0.24px",
    //         }}
    //       >
    //         {pickeUpLoaction}
    //       </Typography>
    //     );
    //   },
    // },
    // {
    //   field: "dropoff_location.address_text",
    //   headerName: "To",
    //   flex: 1,
    //   renderCell: (param) => {
    //     if (!param.row.dropoff_location.address_text) {
    //       return "--";
    //     }

    //     let pickeUpLoaction = param.row.dropoff_location.address_text.replace(
    //       /,/g,
    //       " "
    //     );

    //     return (
    //       <Typography
    //         sx={{
    //           textOverflow: "ellipsis",
    //            width: "100%" ,
    //           height: "31px",
    //           overflow: "hidden",
    //           fontSize: "12px",
    //           fontStyle: "normal",
    //           fontFamily: "Proxima Nova",
    //           whiteSpace: "nowrap",
    //           fontWeight: "400",
    //           letterSpacing: "0.24px",
    //         }}
    //       >
    //         {pickeUpLoaction}
    //       </Typography>
    //     );
    //   },
    // },
    {
      field: "cab_type",
      headerName: "Cab type",
      flex: 0.5,
      renderCell: (param) => {
        if (!param?.row?.cab_type) {
          return "--";
        }

        return (
          <Typography
            sx={{
              textOverflow: "ellipsis",
              width: "auto",
              height: "31px",
              overflow: "hidden",
              fontSize: "12px",
              fontStyle: "normal",
              fontFamily: "Proxima Nova",
              whiteSpace: "nowrap",
              fontWeight: "400",
              letterSpacing: "0.24px",
            }}
          >
            {param.row.cab_type}
          </Typography>
        );
      },
    },
    {
      field: "payment_details.amount",
      headerName: "Trip Fare",
      flex: 0.7,
      renderCell: (param) => {
        if (!param.row.payment_details?.amount) {
          return "--";
        }
        return (
          <Typography
            sx={{
              textOverflow: "ellipsis",
              width: "100%",
              height: "31px",
              overflow: "hidden",
              fontSize: "12px",
              fontStyle: "normal",
              fontFamily: "Proxima Nova",
              whiteSpace: "nowrap",
              fontWeight: "400",
              letterSpacing: "0.24px",
            }}
          >
            {"PHP" + " " + param.row.payment_details.amount}
          </Typography>
        );
      },
    },
    {
      field: "payment_details.payment_method",
      headerName: "Mode",
      flex: 0.5,
      renderCell: (param) => {
        if (!param.row.payment_details?.payment_method) {
          return "--";
        }

        return (
          <Button
            sx={{
              color: "#043A87",
            }}
            variant="text"
            onClick={() => navigate("/customer-payments")}
          >
            <Typography
              sx={{
                textOverflow: "ellipsis",
                fontSize: "13px",
                fontStyle: "normal",
                fontFamily: "Proxima Nova",
                fontWeight: "500",
                color: "#043A87",
              }}
            >
              {param.row.payment_details.payment_method}
            </Typography>
          </Button>
        );
      },
    },
    {
      field: "driver_rating_review.rating",
      headerName: "Rating",
      flex: 0.6,
      renderCell: (param) => {
        if (!param?.row?.driver_rating_review?.rating) {
          return "--";
        }

        <Box sx={{ display: "flex", gap: "10px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.35185 0.692996C5.64006 0.316617 5.99629 0 6.5 0C7.00371 0 7.35994 0.316617 7.64815 0.692995C7.93135 1.06282 8.23165 1.60159 8.59866 2.26007L8.81548 2.64904C9.05282 3.07482 9.11353 3.16527 9.18969 3.22309C9.26299 3.27874 9.35756 3.30978 9.81915 3.41422L10.2424 3.50999C10.9532 3.6708 11.5388 3.80329 11.9653 3.97137C12.408 4.14584 12.7954 4.40299 12.9445 4.88262C13.0921 5.35731 12.9262 5.79402 12.6699 6.20131C12.4208 6.59704 12.0229 7.06231 11.5369 7.63064L11.25 7.96616C10.9374 8.33171 10.8721 8.42116 10.8414 8.51988C10.8101 8.62052 10.813 8.73683 10.8602 9.22418L10.9035 9.67049C10.9771 10.4299 11.0371 11.0493 11.0161 11.521C10.9947 12.001 10.8857 12.4621 10.4895 12.7629C10.0859 13.0693 9.61631 13.0347 9.16298 12.9048C8.72342 12.7789 8.17543 12.5265 7.51002 12.2201L7.1136 12.0376C6.67988 11.8379 6.58702 11.8058 6.5 11.8058C6.41298 11.8058 6.32012 11.8379 5.8864 12.0376L5.48998 12.2201C4.82457 12.5265 4.27658 12.7789 3.83702 12.9048C3.38369 13.0347 2.91409 13.0693 2.5105 12.7629C2.1143 12.4621 2.00527 12.001 1.98388 11.521C1.96287 11.0493 2.0229 10.4299 2.0965 9.67048L2.13975 9.22418C2.18697 8.73683 2.18992 8.62052 2.15862 8.51988C2.12793 8.42116 2.06263 8.33171 1.75003 7.96616L1.46315 7.63067C0.977123 7.06232 0.579238 6.59704 0.330132 6.20131C0.0737533 5.79402 -0.0921286 5.35731 0.0554762 4.88262C0.204619 4.40299 0.592019 4.14584 1.03472 3.97137C1.4612 3.80329 2.04679 3.6708 2.7576 3.50999L2.7961 3.50128L3.18085 3.41422C3.64244 3.30978 3.737 3.27874 3.81031 3.22309C3.88647 3.16527 3.94718 3.07482 4.18452 2.64904L4.40134 2.26006C4.76836 1.60159 5.06865 1.06282 5.35185 0.692996Z"
              fill="#FFC940"
            />
          </svg>

          <Typography>{`(${param.row.driver_rating_review.rating})`}</Typography>
        </Box>;
      },
    },
    {
      field: "driver_rating_review.review",
      headerName: "Review",
      flex: 1,
      renderCell: (param) => {
        if (!param?.row?.driver_rating_review?.review) {
          return "--";
        }

        <Box sx={{ display: "flex", gap: "10px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.35185 0.692996C5.64006 0.316617 5.99629 0 6.5 0C7.00371 0 7.35994 0.316617 7.64815 0.692995C7.93135 1.06282 8.23165 1.60159 8.59866 2.26007L8.81548 2.64904C9.05282 3.07482 9.11353 3.16527 9.18969 3.22309C9.26299 3.27874 9.35756 3.30978 9.81915 3.41422L10.2424 3.50999C10.9532 3.6708 11.5388 3.80329 11.9653 3.97137C12.408 4.14584 12.7954 4.40299 12.9445 4.88262C13.0921 5.35731 12.9262 5.79402 12.6699 6.20131C12.4208 6.59704 12.0229 7.06231 11.5369 7.63064L11.25 7.96616C10.9374 8.33171 10.8721 8.42116 10.8414 8.51988C10.8101 8.62052 10.813 8.73683 10.8602 9.22418L10.9035 9.67049C10.9771 10.4299 11.0371 11.0493 11.0161 11.521C10.9947 12.001 10.8857 12.4621 10.4895 12.7629C10.0859 13.0693 9.61631 13.0347 9.16298 12.9048C8.72342 12.7789 8.17543 12.5265 7.51002 12.2201L7.1136 12.0376C6.67988 11.8379 6.58702 11.8058 6.5 11.8058C6.41298 11.8058 6.32012 11.8379 5.8864 12.0376L5.48998 12.2201C4.82457 12.5265 4.27658 12.7789 3.83702 12.9048C3.38369 13.0347 2.91409 13.0693 2.5105 12.7629C2.1143 12.4621 2.00527 12.001 1.98388 11.521C1.96287 11.0493 2.0229 10.4299 2.0965 9.67048L2.13975 9.22418C2.18697 8.73683 2.18992 8.62052 2.15862 8.51988C2.12793 8.42116 2.06263 8.33171 1.75003 7.96616L1.46315 7.63067C0.977123 7.06232 0.579238 6.59704 0.330132 6.20131C0.0737533 5.79402 -0.0921286 5.35731 0.0554762 4.88262C0.204619 4.40299 0.592019 4.14584 1.03472 3.97137C1.4612 3.80329 2.04679 3.6708 2.7576 3.50999L2.7961 3.50128L3.18085 3.41422C3.64244 3.30978 3.737 3.27874 3.81031 3.22309C3.88647 3.16527 3.94718 3.07482 4.18452 2.64904L4.40134 2.26006C4.76836 1.60159 5.06865 1.06282 5.35185 0.692996Z"
              fill="#FFC940"
            />
          </svg>

          <Typography>{`(${param.row.driver_rating_review.review})`}</Typography>
        </Box>;
      },
    },
  ];
  const [fieldsName, setFieldName] = useState(() => {
    return TData?.columns;
  });
  const [columnFields, setColumnFields] = useState(columns);

  const [search, setSearch] = useState("");
  const [rating, setRating] = useState(getQuery("rating") ?? "");
  const reasonParams = searchParams.get("reason");
  const [reason, setReason] = useState(
    reasonParams || location?.state?.reason || ""
  );

  const [review, setReview] = useState(getQuery("rating_and_reviews") ?? "");
  const [time, setTime] = useState({
    minTime: getQuery("booking_time_gte")
      ? dayjs(getQuery("booking_time_gte"))
      : "",
    maxTime: getQuery("booking_time_lte")
      ? dayjs(getQuery("booking_time_lte"))
      : "",
  });

  const [showSort, SetShowSort] = useState({ right: false });

  const [sort, setSort] = useState({
    bookingId: getQuery("booking_id_sort") ?? "",
    rating: getQuery("rating_sort") ?? "",
  });

  const specialFilter =
    pathname === "/customer-trips" ? "rider_ticket" : "driver_ticket";

  // Debounce
  const searchDebounce = useDebounce(search, 1500);

  // control mui pagination
  const page = parseInt(searchParams.get("page"), 0) || 0;
  const pageSize = parseInt(searchParams.get("limit"), 15) || 15;
  const [paginationModel, setPaginationModel] = React.useState({
    page: page || 0,
    pageSize: pageSize || 10,
  });

  // Pagination state
  const [totalCount, setTotalCount] = useState(0);

  const [activeButton, setActiveButton] = useState("Trips");

  const setIdQueryParam = (id) => {
    if (!id) return;
    searchParams.set("id", id);
    setSearchParams(searchParams, { replace: true });
  };

  const setDrawerQueryParam = (id) => {
    if (!id) return;
    searchParams.set("drawer", id);
    setSearchParams(searchParams, { replace: true });
  };

  const navigate = useNavigate();
  // mui toggleDrawer funtion for side panel
  const toggleDrawer = (anchor, open) => (event) => {
    if (!open) {
      setDriverId("");
    }
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerQueryParam(open);
    setDriverDetails((prev) => ({ ...prev, [anchor]: open }));
  };

  const handlePaginationModel = (value) => {
    // Update the search parameters
    searchParams.set("page", value?.page || 0);
    searchParams.set("limit", value?.pageSize || 10);

    // Set the new search parameters with the replace option
    setSearchParams(searchParams, { replace: true });

    setPaginationModel((prev) => ({ ...prev, ...value }));
  };

  const toggleFilterDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowFilter((prev) => ({ ...prev, [anchor]: open }));
  };

  const toggleSortDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    SetShowSort({ ...showSort, [anchor]: open });
  };

  const handleNavigate = (id) => {
    if (id) {
      navigate(`/driver/${id}`);
    }
  };
  // Function to handle click event by name
  const handleSelectNameClick = (name) => {
    setActiveButton(name);
  };

  const toggleCustomerFilterDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setCustomerFilter((prev) => ({ ...prev, [anchor]: open }));
  };

  const checkQuery = (download = false) => {
    const query = [];

    if (pathname === "/customer-trips") {
      query.push(`is_rider_trip=${true}`);
    }

    if (reason) {
      searchParams.set("reason", reason);
      setSearchParams(searchParams, { replace: true });
      query.push(`ride_cancellation_reason=${reason}`);
    }

    if (download) {
      query.push(`is_download=${true}`);
    }

    if (location?.state?.special) {
      query.push(`special=${location.state.special}`);
    }

    if (range) {
      searchParams.set("range", range);
      setSearchParams(searchParams, { replace: true });
      query.push(`booking_day=${range}`);
    }

    if (rideStatus !== "") {
      if (location?.state?.status) {
        searchParams.set("ride_status", location?.state?.status);
        setSearchParams(searchParams, { replace: true });
      }

      query.push(
        `ride_status=${rideStatus || (location?.state?.status ?? "")}`
      );
    }

    if (bookingDay !== "") {
      query.push(`booking_day=${bookingDay}`);
    }

    if (searchDebounce && search) {
      query.push(`search=${searchDebounce}`);
    }

    if (date.startDate && date.endDate) {
      let startDate = `${date.startDate.year()}-${
        date.startDate.month() + 1
      }-${date.startDate.date()}`;
      let endDate = `${date.endDate.year()}-${
        date.endDate.month() + 1
      }-${date.endDate.date()}`;
      query.push(`date_gte=${startDate}&date_lte=${endDate}`);
    }

    if (carType !== "") {
      query.push(`car_type=${carType}`);
    }

    if (rating !== "") {
      query.push(`rider_rating=${rating}`);
    }

    if (cancelledBy !== "") {
      if (location?.state?.cancelledBy) {
        setQuery("cancelled_by", cancelledBy, setSearchParams);
      }

      query.push(`cancelled_by=${cancelledBy}`);
    }

    if (review !== "") {
      query.push(`rider_rating_and_review=${review}`);
    }

    if (sort?.bookingId) {
      query.push(`booking_id_sort=${sort?.bookingId}`);
    }

    if (sort?.rating) {
      query.push(`rating_sort=${sort?.rating}`);
    }

    if (tripsFilter) {
      query.push(`trips_fare_sort=${tripsFilter}`);
    }

    if (time.minTime && time.maxTime) {
      query.push(
        `min_booking_time=${convertTo24HourFormat(
          time.minTime
        )}&max_booking_time=${convertTo24HourFormat(time.maxTime)}`
      );
    }

    if (customerId) {
      const riderId = customerId;
      query.push(`rider_id=${riderId}`);
    }

    let qurys = query.length > 1 ? query.join("&") : query.join("");

    return qurys;
  };

  // Funcion to Fetch Trips data from server
  const getTrips = async (qurys = []) => {
    setisLoading(true);
    let url =
      qurys.length > 0
        ? `/crm/ride/?page=${paginationModel.page + 1}&limit=${
            paginationModel.pageSize
          }&${qurys}`
        : `/crm/ride/?page=${paginationModel.page + 1}&limit=${
            paginationModel.pageSize
          }`;
    // passing token in headers

    try {
      // setLoader(true);
      await axiosInstance.get(url).then((res) => {
        setLoader(false);
        setTotalCount(res.data.data.total_count);
        setTrips(res?.data?.data?.records ?? []);
      });
    } catch (error) {
      setLoader(false);
      console.log("Error while Fetching Driver Trips", error);
    } finally {
      setisLoading(false);
    }
  };

  const handleColumnAdd = (checked, name, label) => {
    setColumnFields((prevFields) => {
      if (checked) {
        // If checked, add the field to the array

        if (name.includes("date")) {
          return [
            ...prevFields,
            {
              field: name,
              headerName: label,
              minWidth: 150,
              renderCell: (params) => {
                if (!params?.value) {
                  return "--";
                }

                const date = formatDate(params?.value);
                return <>{date}</>;
              },
            },
          ];
        } else {
          return [
            ...prevFields,
            {
              field: name,
              headerName: label,
              width: 150,
              renderCell: (params) => {
                if (!params?.value) {
                  return "--";
                }

                return <>{params.value}</>;
              },
            },
          ];
        }
      } else {
        // If unchecked, filter out the field from the array
        return prevFields.filter((field) => field.field !== name);
      }
    });
  };

  // Function
  const downloadFile = async () => {
    let qurys = checkQuery(true) ?? [];
    let url =
      qurys.length > 0
        ? `/crm/ride/?page=${paginationModel.page + 1}&limit=${
            paginationModel.pageSize
          }&${qurys}`
        : `/crm/ride/?page=${paginationModel.page + 1}&limit=${
            paginationModel.pageSizes
          }`;

    try {
      // setLoader(true);
      await axiosInstance.get(url).then((res) => {
        notifyToast.success(
          "Success",
          "The file has been successfully sent to your email. Please check your inbox.",
          {
            autoClose: 5000,
          }
        );
      });
    } catch (error) {
      setLoader(false);
      let message = error?.response?.data?.message;
      if (message) {
        showErrorToast(message);
      }
      console.log("Error while Fetching Driver Trips", error);
    }
  };

  // config

  const RideStatusConfig = [
    {
      text: "First Mile",
      fill: "#fff",
    },
    {
      text: "Ongoing Ride",
      fill: "#043A87",
    },
    {
      text: "Completed Ride",
      fill: "#FFC940",
    },
    {
      text: "Cancelled Ride",
      fill: "#D1D1D1",
    },
    {
      text: "Driver Not Found",
      fill: "red",
    },
  ];

  // config
  const sub_section_to_label = {
    trips: "Trips",
  };

  const allowed_sub_sections = permissions?.SUB_SECTIONS ?? ["trips"];

  const tableHeadButtonConfig = allowed_sub_sections?.map((sub_section) => {
    return {
      label: sub_section_to_label[sub_section],
      value: "Trips",
      totalCount,
      onClick: handleSelectNameClick,
    };
  });

  const filterOptions = [
    {
      summryText: "Ride Status",
      state: rideStatus,
      filterName: "ride_status",
      setState: setRideStatus,
      options: [
        { label: "First Mile", value: "REQUESTED" },
        { label: "Ongoing", value: "ONGOING" },
        { label: "Completed", value: "COMPLETED" },
        { label: "Cancelled", value: "CANCELLED" },
        { label: "Driver Not Found", value: "DRIVER_NOT_FOUND" },
        { label: "Live Trips", value: "LIVE_TRIP" },
      ],
    },
    {
      summryText: "Cancelled By",
      filterName: "cancelled_by",
      state: cancelledBy,
      setState: setCancelledBy,
      options: [
        { label: "Rider", value: "RIDER" },
        { label: "Driver", value: "DRIVER" },
      ],
    },
    {
      summryText: "Booking Day",
      filterName: "booking_day",
      state: bookingDay,
      setState: setBookingDay,
      options: [
        { label: "Recent", value: "Recent", disabled: true },
        { label: "Last week", value: "last_week" },
        { label: "Yesterday", value: "Yesterday" },
        { label: "Last month", value: "last_month" },
        { label: "Last year", value: "last_year" },
      ],
      date: {
        customDateState: date,
        setState: setDate,
        customeDateHandler,
        customDateHeader: "Custom",
        filterName: ["booking_date_gte", "booking_date_lte"],
        customDateLabel: ["From", "To"],
        customDateName: ["startDate", "endDate"],
      },
    },
    {
      summryText: "Booking time",
      time: {
        timeLabel: ["Min Time", "Max Time"],
        filterName: ["booking_time_gte", "booking_time_lte"],
        customTimeName: ["minTime", "maxTime"],
        timeState: time,
        setState: setTime,
        customeTimeHandler,
      },
    },
    {
      summryText: "Car Type",
      filterName: "car_type",
      state: carType,
      setState: setCarType,
      options: [
        { value: "COMPACT", label: "Compact" },
        { value: "TAXI", label: "Taxi" },
        { value: "PLUS", label: "Plus" },
        // { value: "LUXURY", label: "Luxury" }
      ],
    },
    {
      summryText: "Rating",
      filterName: "rating",
      state: rating,
      setState: setRating,
      options: [
        { value: "5", label: "5 star" },
        { value: "4", label: "4 star" },
        { value: "3", label: "3 star" },
        { value: "2", label: "2 star" },
        { value: "1", label: "1 star" },
      ],
    },
    {
      summryText: "Rating & Reviews",
      filterName: "rating_and_reviews",
      state: review,
      setState: setReview,
      options: [
        { value: "rating_only", label: "Rating only" },
        { value: "review_only", label: "Review only" },
        { value: "rating_and_review_both", label: "Rating and review both" },
        { value: "no_rating_and_review", label: "No rating and review" },
      ],
    },
    {
      summryText: "Range",
      state: range,
      ignorefilter: true,
      filterName: "range",
      setState: setRange,
      options: [
        {
          label: "Today",
          value: "today",
        },
        {
          label: "Yesterday",
          value: "yesterday",
        },
        {
          label: "Last Week",
          value: "WEEKLY",
        },
        {
          label: "Last Month",
          value: "MONTHLY",
        },
      ],
    },
    {
      summryText: "Rider Id",
      filterName: "rider id",
      ignorefilter: true,
      state: customerId,
      setState: setCustomerId,
      options: [
        {
          label: customerId,
          value: customerId,
        },
      ],
    },
    {
      summryText: "Reason",
      filterName: "reason",
      ignorefilter: true,
      state: reason,
      setState: setReason,
      options: [],
    },
    // {
    //   summryText: "Range",
    //   state: review,
    //   setState: setReview,
    //   options: [
    //     { value: "rating_only", label: "Rating only" },
    //     { value: "review_only", label: "Review only" },
    //     { value: "rating_and_review_both", label: "Rating and review both" },
    //     { value: "no_rating_and_review", label: "No rating and review" },
    //   ],
    // },
    // {
    //   summaryText: "Trip Fare",
    //   state: trips,
    //   setState: setTrips,
    //   options: [
    //     { value: "min", label: "Minimum" },
    //     { value: "max", label: "Maximum" },
    //   ],
    // },
  ];

  const sortFilterConfig = {
    options: [
      {
        ...bookingIdObj,
        state: sort?.bookingId ?? [],
        filterName: "booking_id_sort",
      },
      {
        summaryText: "Rating",
        name: "rating",
        filterName: "rating_sort",
        state: sort?.rating ?? [],
        options: commonSortOptions,
      },
    ],
    handleClearState: () => {},
    // handleFilterState: handleFilterState,
  };

  const buttonConfig = {
    buttonConfig: tableHeadButtonConfig,
    active: activeButton,
    totalcount: totalCount,
  };

  useEffect(() => {
    setFieldName(TData?.columns);
  }, []);

  useEffect(() => {
    if (["RIDER", "DRIVER"].includes(cancelledBy)) {
      TData?.columns.forEach((res) => {
        handleColumnAdd(true, res.key, res.name);
      });
    }
  }, []);

  // useEffect(() => {
  //   console.log(location?.state);
  //   if (location?.state && Object.entries(location.state).length > 0) {
  //     if (!rideStatus && location?.state?.status) {
  //       setRideStatus(location?.state?.rideStatus);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    let qurys = checkQuery();

    getTrips(qurys);
  }, [
    rideStatus,
    bookingDay,
    date,
    carType,
    paginationModel.page,
    paginationModel.pageSize,
    searchDebounce,
    review,
    rating,
    tripsFilter,
    time.minTime,
    time.maxTime,
    cancelledBy,
    range,
    reason,
    customerId,
    sort,
  ]);

  return (
    <>
      {loader ? (
        <div>loading..</div>
      ) : (
        <Box
          sx={{
            margin: "20px",
          }}
        >
          <SearchActionComponent
            value={search}
            setState={setSearch}
            toggleDrawer={toggleFilterDrawer}
            placeholder={"Search Customer , Driver, Booking ID"}
            toggleSortDrawer={toggleSortDrawer}
            require={{
              search: true,
              filter: true,
              download: pathname !== "/customer-trips" ? true : false,
              sort: false,
            }}
            downloadFile={downloadFile}
          >
            <AddColumn
              fieldsName={fieldsName}
              handleColumnAdd={handleColumnAdd}
              columnFields={columnFields}
            />
          </SearchActionComponent>

          <FilteredResults options={filterOptions} />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <CommonTableHead
              totalcount={buttonConfig?.totalcount}
              ButtonConfig={tableHeadButtonConfig}
              active={buttonConfig?.active}
            />
          </Box>

          <DataTable
            row={trips || []}
            columns={columns}
            sx={{ cursor: "pointer" }}
            paginationModel={paginationModel}
            loading={isLoading}
            setPaginationModel={setPaginationModel}
            totalCount={Math.ceil(totalCount)}
          />

          {showDriverDetails["right"] && (
            <SwipeableDrawerComponent
              isOpen={showDriverDetails["right"]}
              anchor="right"
              onClose={(e) => {
                removeQuery("drawer", setSearchParams);
                removeQuery("id", setSearchParams);
                toggleDrawer("right", false)(e);
              }}
            >
              <DetailsSidePanel
                setDriverId={setDriverId}
                DId={driverId}
                anchor={"right"}
                role={pathname === "/customer-trips" ? "customer" : ""}
                specialFilter={specialFilter}
                toggleDrawer={toggleDrawer}
              />
            </SwipeableDrawerComponent>
          )}

          {showFilter && (
            <SwipeableDrawerComponent
              isOpen={showFilter["right"]}
              anchor="right"
              onClose={toggleFilterDrawer("right", false)}
            >
              <CustomFilter
                filterOptions={filterOptions}
                toggleDrawer={() => toggleFilterDrawer("right", false)}
              />
            </SwipeableDrawerComponent>
          )}

          {showCustomerFilter["right"] && (
            <SwipeableDrawerComponent
              isOpen={showCustomerFilter["right"]}
              anchor="right"
              onClose={toggleCustomerFilterDrawer("right", false)}
            >
              <DetailsSidePanel
                DId={driverId}
                anchor={"right"}
                toggleDrawer={toggleCustomerFilterDrawer}
                role="customer"
                specialFilter={"rider_ticket"}
                fetchFunction={getTrips}
              />
            </SwipeableDrawerComponent>
          )}

          {showSort.right && (
            <SwipeableDrawerComponent
              isOpen={showSort["right"]}
              onClose={toggleSortDrawer("right", false)}
              anchor="right"
            >
              <SortPanel
                toggleDrawer={toggleSortDrawer}
                sortOptions={sortFilterConfig?.options}
                // handleClear={handleClear}
                setState={setSort}
              />
            </SwipeableDrawerComponent>
          )}

          <Box
            sx={{
              width: "auto",
              margin: "auto",
              padding: "25px 0",
            }}
          >
            <Typography>Ride Status</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginTop: "12px",
              }}
            >
              {RideStatusConfig.length > 0 &&
                RideStatusConfig.map((res, index) => (
                  <StatusIndicator
                    key={res.text}
                    index={index}
                    text={res.text}
                    fill={res.fill}
                  />
                ))}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DriverTrips;
