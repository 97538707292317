import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useSettings } from "../../hooks/useSettings";
import NoComments from "./NoComments";
import CommentsSendMessage from "./CommentsSendMessage";

const CommentSidebar = ({
  comments = {},
  toggleDrawer,
  setComments,
  getTrips,
}) => {
  const { dateformat, timezone, formatDate, timeFormat } = useSettings();

  const hasComments = comments?.data ?? [];

  console.log(comments);

  return (
    <Box
      sx={{
        width: "446px",
        overflow: "hidden",
        height: "100vh",
        boxSizing: "border-box",
        background: "#FFF",
        padding: "10px",
        boxShadow: "-4px 4px 18px 0px rgba(0, 0, 0, 0.07)",
        fontFamily: "Proxima Nova",
      }}
    >
      {/* Header */}

      <Box
        sx={{
          width: "100%",
          margin: "0 auto 15px auto",
          maxHeight: "50px",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Proxima Nova",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
              letterSpacing: "0.32px",
              fontSize: "16px",
            }}
          >{`Booking ID :${comments?.bookingId}`}</Typography>
          <Button
            sx={{
              width: "28px",
              height: "28px",
              color: "#000",
              flexShrink: "0",
            }}
            onClick={(e) => {
              e.stopPropagation();
              let handleEvevnt = toggleDrawer("right", false);
              handleEvevnt(e);
            }}
          >
            <CloseIcon />
          </Button>
        </Box>
        <Typography
          sx={{
            fontFamily: "Proxima Nova",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            color: "#6F747E",
          }}
        >
          {/* {formatDate(sidePanelData?.created_at ?? "", timezone, dateformat)},{" "}
          {formatDate(sidePanelData?.created_at, timezone, timeFormat)} */}
        </Typography>
      </Box>
      <Divider
        sx={{
          width: "414px",
          margin: "20px 10px 0px auto",
        }}
      />

      {hasComments?.length > 0 ? (
        <>
          <Box
            sx={{
              height: "calc(100% - 180px)",
              overflowY: "auto",
              pr: 1, // Adjust padding to prevent clipping
              "&::-webkit-scrollbar": {
                width: "2px", // Set scrollbar width for WebKit browsers
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "black", // Set thumb color
                borderRadius: "4px", // Optional: round the edges
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f0f0f0", // Optional: Set track background color
              },
              scrollbarWidth: "thin", // Set a thin scrollbar in Firefox
              scrollbarColor: "black #f0f0f0", // Thumb and track color for Firefox
            }}
          >
            {hasComments.map((item, index) => (
              <React.Fragment key={index}>
                <Card
                  variant="outlined"
                  sx={{
                    border: "none",
                    borderBottom: "1px solid #00000024",
                    boxShadow: "none",
                    borderRadius: "0px",
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography variant="subtitle1" fontWeight="bold">
                        {item.reviewer_name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mb: 1 }}
                      >
                        {formatDate(item.comment_at, timezone, dateformat)},{" "}
                        {formatDate(item.comment_at, timezone, timeFormat)}
                      </Typography>
                    </Box>
                    <Typography variant="body2">{item.comment}</Typography>
                  </CardContent>
                </Card>
                {index < comments.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </Box>
        </>
      ) : (
        <>
          {/* NO COMMENTS SECTION */}
          <NoComments />
        </>
      )}

      {/* Footer */}
      {hasComments && (
        <CommentsSendMessage rideId={comments?.ride_id} getTrips={getTrips} />
      )}
    </Box>
  );
};

export default CommentSidebar;
