import React, { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

// Assuming these are imported from your components
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";

const Layout = () => {
  // Use useLocation hook to get current pathname
  const { pathname } = useLocation();

  // Media query hooks for responsive design
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isMobileView = useMediaQuery("(max-width:820px)");

  // Memoize excluded paths to prevent unnecessary re-computations
  const navbarExcludedPaths = useMemo(
    () => [
      "driver/",
      "new-driver",
      "operator-management/new-operator",
      "operator/",
      "new-center",
      "training-center/",
    ],
    []
  );

  // Determine if Navbar should be hidden based on current path
  const shouldHideNavbar = useMemo(
    () =>
      navbarExcludedPaths.some((path) => pathname.substring(1).includes(path)),
    [pathname, navbarExcludedPaths]
  );

  return (
    <Box
      display={isNonMobile ? "flex" : "block"}
      width="100%"
      height="100%"
      sx={{
        backgroundColor: "#F7F9FC",
        boxSizing: "border-box",
        overflow: "auto",
        // Custom scrollbar styling
        scrollbarWidth: "thin",
        scrollbarColor: "transparent transparent", // for Firefox
        "&::-webkit-scrollbar": {
          width: "0px", // width of the scrollbar
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "transparent", // color of the thumb
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent", // color of the track
        },
      }}
    >
      {/* Sidebar component */}
      <Box
        sx={{
          maxWidth: "220px",
          width: "100%",
        }}
      >
        <Sidebar isNonMobile={isNonMobile} />
      </Box>

      {/* Main content area */}
      <Box flexGrow={1} overflow="auto" boxSizing="border-box">
        {/* Conditionally render Navbar */}
        {!shouldHideNavbar && <Navbar isMobileView={isMobileView} />}

        {/* Render child routes */}
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
