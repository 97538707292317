import "./App.css";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Overview from "./components/Overview";
import Customer from "./components/Customer";
import Driver from "./components/Driver";
import Layout from "./components/Layout";
import Login from "./components/Login";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import DriverDetails from "./components/DriverDetails";
import Appointments from "./components/Appointments";
import NewDriver from "./components/NewDriver";
import DriverTrips from "./components/DriverTrips";
import Settlements from "./components/Settlements";
import RideTracking from "./components/RideTracking";
import NotFound from "./components/404NotFound";
import { ToastContainer, toast } from "react-toastify";
import Tickets from "./components/Tickets";
import Payments from "./components/Payments";
import RatingAndReview from "./components/RatingAndReview";
import OperatorManagement from "./components/OperatorManagement";
import NewOperator from "./components/NewOperator";
import OperatorDetails from "./components/OperatorDetails";
import ComingSoon from "./components/ComingSoon";
import { useEffect, useState } from "react";
import { getFirebaseRemoteConfig } from "./utils/getFirebaseConfig";
import { setConfigValues } from "./state/configSlice";
import CustomerSos from "./components/CustomerSos";
import { Button } from "@mui/material";
import { useExternalScript } from "./hooks/useExternalScript";
import { updateSosData } from "./state/sosSlice";
import TrainingCenter from "./components/TrainingCenter";
import NewTraningCenter from "./components/NewTraningCenter";
import TrainingCenterDetails from "./components/TrainingCenterDetails";
import { pdfjs } from "react-pdf";
import axiosInstance from "./utils/axiosConfig";
import { setRolePermission } from "./state/rolePermissionsSlice";
import Loader from "./components/Loader";
import CustomerTickets from "./components/CustomerTickets";
import DriverTickets from "./components/DriverTickets";
import CustomerTrips from "./components/CustomerTrips";
import FinanceManagement from "./components/FinanceManagement";
import { CustomerFlagged } from "./components/flagged/CustomerFlagged";
import TripsFlagged from "./components/flagged/TripsFlagged";
import PushNotification from "./components/PushNotification";
import SpecialCategory from "./components/SpecialCategory";
import Topup from "./components/Topup";

const routeComponents = {
  "/overview": <Overview />,
  "/customer": <Customer />,
  "/driver": <Driver />,
  "/new-driver": <NewDriver />,
  "/driver/:id": <DriverDetails />,
  "/appointments": <Appointments />,
  "/driver-trips": <DriverTrips />,
  "/customer-trips": <CustomerTrips />,
  "/earnings-&-settlements": <Settlements />,
  "/customer-tickets": <CustomerTickets />,
  "/driver-tickets": <DriverTickets />,
  "/customer-payments": <Payments />,
  "/ratings-&-reviews": <RatingAndReview />,
  "/operator-management": <OperatorManagement />,
  "/operator-management/new-operator": <NewOperator />,
  "/operator/:id": <OperatorDetails />,
  "/training-center": <TrainingCenter />,
  "/customer-sos": <CustomerSos />,
  "/new-center": <NewTraningCenter />,
  "/training-center/:id": <TrainingCenterDetails />,
  "/finance-management": <FinanceManagement />,
  "/customer-flagged": <CustomerFlagged />,
  "/trips-flagged": <TripsFlagged />,
  "/push-notification": <PushNotification />,
  "/special-category": <SpecialCategory />,
  "/topup": <Topup />,
};

function App() {
  const pdfurl = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  pdfjs.GlobalWorkerOptions.workerSrc = pdfurl;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [routes, setRoutes] = useState([]);

  const config = useSelector((store) => store.config);

  const { isAuth, admin_sos_websocket_room_id, refreshToken } = useSelector(
    (store) => {
      return {
        isAuth: Boolean(store.auth.token),
        admin_sos_websocket_room_id: store.auth.admin_sos_websocket_room_id,
        refreshToken: store.auth.refreshToken,
      };
    },
    shallowEqual
  );

  const url = `https://maps.googleapis.com/maps/api/js?key=${
    process.env.REACT_APP_GOOGLE_API_KEY ?? ""
  }&loading=async&libraries=geometry,drawing,marker,journeySharing&callback=initMap`;

  const script = useExternalScript(url, isAuth);

  // useEffect(() => {
  //   if (
  //     !config ||
  //     !config.configValues.server_base_url ||
  //     !config.configValues.websocket_server_base_url ||
  //     !config.configValues.google_api_key
  //   ) {
  //     getFirebaseRemoteConfig().then((res) => {
  //       dispatch(
  //         setConfigValues({
  //           google_api_key: res.google_api_key,
  //           server_base_url: res.server_base_url,
  //           websocket_server_base_url: res.websocket_server_base_url,
  //         })
  //       );
  //     });
  //   }
  // }, []);

  useEffect(() => {
    if (!isAuth) return;

    const fetchPermissionDetails = async () => {
      setLoader(true);
      const url = "/crm/role/permissions/";
      try {
        const response = await axiosInstance.get(url);

        dispatch(
          setRolePermission({
            allowed_sections: response?.data?.data?.allowed_sections ?? [],
            allowed_permissions: response?.data?.data.allowed_permissions ?? {},
          })
        );

        setRoutes(response?.data?.data.allowed_routes);
      } catch (error) {
        console.log(error);
      } finally {
        setLoader(false);
      }
    };

    fetchPermissionDetails();
  }, []);

  // socket for sos and to keep tracp track rider
  useEffect(() => {
    if (!admin_sos_websocket_room_id && !isAuth) return;
    const websocketBaseUrl =
      config?.configValues?.websocket_server_base_url ?? "";
    let url = `${websocketBaseUrl}/ws/socket-server/${admin_sos_websocket_room_id}/`;
    const socket = new WebSocket(url);

    socket.addEventListener("message", (res) => {
      let sosData = JSON.parse(res?.data ?? {});
      if (sosData.type === "rider_sos") {
        toast.error(
          <Button
            onClick={() => {
              navigate("/customer-sos", {
                state: {
                  sos_id: sosData?.data?.id ?? "",
                  booking_id: sosData?.data?.booking_id ?? "",
                  ride_id: sosData?.data?.ride_id,
                },
              });
            }}
          >
            {sosData?.customer_details?.full_name ?? ""}
          </Button>,
          {
            autoClose: 9000,
            closeOnClick: false,
            theme: "colored",
          }
        );

        dispatch(updateSosData(sosData));
      }
    });

    // Clean up the socket connection when the component is unmounted
    return () => {
      socket.close();
    };
  }, [admin_sos_websocket_room_id]);

  return loader ? (
    <Loader />
  ) : (
    <div className="app">
      <ToastContainer autoClose={5000} theme="dark" position="bottom-center" />
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/topup" element={<Topup />} /> */}
        <Route path="/ride/:ride_uuid" element={<RideTracking />} />
        <Route element={<Layout />}>
          <Route
            path="/"
            element={
              !isAuth ? (
                <Navigate to={"/login"} />
              ) : (
                <Navigate to={routes[0]} replace />
              )
            }
          />
          {routes.length > 0 &&
            routes.map((res) => (
              <Route
                key={res}
                path={res}
                element={
                  isAuth ? routeComponents[res] : <Navigate to="/login" />
                }
              />
            ))}
        </Route>
        <Route path="/:slug" element={<NotFound />} />

        <Route path="*" element={<NotFound />} />
        <Route path="coming-soon" element={<ComingSoon />} />
      </Routes>
    </div>
  );
}

export default App;
