import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ImageUpload from "./ImageUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import axiosInstance from "../utils/axiosConfig";
import CloseIcon from "@mui/icons-material/Close";
import { notifyToast } from "../utils/notify";

// inital value
const initalSelectImage = {
  file: null,
  imgurl: null,
};

const initialTopUp = {
  amount: 0,
  remark: "",
  reciptNumber: "",
};

// Component
const LabelInputWrapper = ({ children }) => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  };

  return <Box sx={containerStyle}>{children}</Box>;
};

const Label = ({ children, ...rest }) => {
  const labelStyle = {
    color: "#6F747E",
    fontFamily: "Proxima Nova",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "17.05px",
    textAlign: "left",
  };

  return (
    <label style={labelStyle} {...rest}>
      {children}
    </label>
  );
};

const InputField = ({
  value,
  handleInputChange,
  placeholder,
  name,
  type = "string",
  label,
}) => {
  return (
    <LabelInputWrapper>
      <Label htmlFor={label}>{label}</Label>
      <input
        id={label}
        value={value}
        onChange={handleInputChange}
        style={inputStyle}
        type={type}
        placeholder={placeholder}
      />
    </LabelInputWrapper>
  );
};

// CSS styles for input
const inputStyle = {
  height: "35px",
  padding: "10px 10px 10px 16px",
  gap: "10px",
  borderRadius: "12px",
  border: "1px solid #E3E7EF",
  outline: "none",
};

// main component
const TopupModal = ({ open, handleClose, driverid }) => {
  const [topup, setTopUp] = useState(initialTopUp);
  const [loading, setLoading] = useState(false);

  const [view, setView] = useState(false);
  const [id, setId] = useState("");

  const [selectedImage, setSelectedImage] = useState(initalSelectImage);

  const handleView = () => {
    setView(false);
  };

  const handleInputChange = (key) => (e) => {
    setTopUp((prev) => ({ ...prev, [key]: e.target.value }));
  };

  const handleSaveProfile = (data) => {
    handleSetId(data);
  };

  const handleSetId = (data) => {
    setId(data?.id ?? "");
  };

  const handleClearState = (e, showToast = false) => {
    setSelectedImage(initalSelectImage);
    setId("");
    setTopUp(initialTopUp);
    if (showToast) {
      console.log(showToast);
      notifyToast.success("Success", "Top-up successful!"); // Show success toast message
    }
    handleClose(false);
  };

  const handleTopUp = async () => {
    const obj = {
      driver_id: driverid ? Number(driverid) : "",
      amount: 0,
      proof_of_payment_image_id: "",
      metadata: {},
    };

    if (topup?.amount > 0) {
      obj.amount = Number(topup?.amount);
    } else {
      notifyToast.error("Error", "Please enter a valid top-up amount."); // Error: invalid or missing amount
      return;
    }

    if (id) {
      obj.proof_of_payment_image_id = id;
    } else {
      notifyToast.error("Error", "Proof of payment image is required."); // Error: missing proof of payment
      return;
    }

    if (topup?.reciptNumber) {
      obj.metadata.receipt_number = topup?.reciptNumber;
    }

    if (topup?.remark) {
      obj.metadata.remark = topup?.remark;
    } else {
      notifyToast.error("Error", "Remark is required."); // Error: missing remark
      return;
    }

    let url = "/crm/driver/add-topup/";

    setLoading(true);

    try {
      const response = await axiosInstance.post(url, obj);

      // Check if the response is successful
      if (response?.status === 200 || response?.status === 201) {
        handleClearState(true);
        notifyToast.success("Success", "Top-up successful!");
      } else {
        notifyToast.error("Error", "Something went wrong during the top-up."); // Handle unexpected status codes
      }
    } catch (error) {
      console.log(error, "Error while top-up"); // Log the error for debugging
      notifyToast.error(
        "Error",
        "Failed to complete top-up. Please try again."
      ); // Show error toast message
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={(e) => {
        handleClearState(false);
        handleClose(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          outline: "none",
          bgcolor: "background.paper",
          p: "20px 20px 30px 20px",
          borderRadius: 1, // Optional: to add rounded corners
          minWidth: 500, // Optional: to set a minimum width for the modal
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            id="modal-modal-title"
            sx={{
              marginBottom: "25px",
            }}
            variant="h6"
            component="h2"
          >
            Top up
          </Typography>

          <IconButton
            onClick={() => {
              handleClearState();
            }}
          >
            <CloseIcon
              sx={{
                color: "#000",
              }}
            />
          </IconButton>
        </Box>

        <Stack
          gap={"15px"}
          width={"100%"}
          boxSizing={"border-box"}
          sx={{
            p: "5px",
            maxHeight: "400px", // Limits the height to 400px
            overflowY: "scroll", // Enables vertical scrolling if content exceeds 400px height
            overflowX: "hidden",
            position: "relative",
          }}
        >
          {/* Amount Field */}
          <InputField
            value={topup?.amount}
            handleInputChange={handleInputChange("amount")}
            placeholder="Enter Amount"
            label="Amount"
            type="number"
          />

          <LabelInputWrapper>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Label>Proof Of Payment</Label>

              {selectedImage?.file && (
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      setSelectedImage(initalSelectImage);
                      setId(null);
                    }}
                  >
                    <DeleteIcon
                      sx={{
                        width: "20px",
                        height: "20px",
                        color: "red",
                      }}
                    />
                  </IconButton>
                </Stack>
              )}
            </Box>
            <ImageUpload
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              setView={setView}
              handleSaveProfile={handleSaveProfile}
            />
          </LabelInputWrapper>

          {/* Receipt Number Field */}
          <InputField
            value={topup?.reciptNumber}
            handleInputChange={handleInputChange("reciptNumber")}
            placeholder="Enter Receipt Number"
            label="Receipt Number"
          />

          <LabelInputWrapper>
            <Label>Remark</Label>
            <textarea
              value={topup?.remark}
              onChange={handleInputChange("remark")}
              style={{
                resize: "none",
                height: "120px",
                padding: "10px 10px 10px 16px",
                gap: "10px",
                borderRadius: "12px",
                border: "1px solid #E3E7EF",
                outline: "none",
              }}
              placeholder="Add Remark"
            />
          </LabelInputWrapper>

          <Button
            sx={{
              background: "#111",
              borderRadius: "10px",
              position: "sticky",
              bottom: "0",
              width: "100%",
              "&:hover": {
                background: "#111",
              },
            }}
            disabled={loading}
            variant="contained"
            onClick={handleTopUp}
          >
            {loading ? "Loading..." : "Topup"}
          </Button>
        </Stack>

        <Modal
          open={view}
          onClose={handleView}
          aria-labelledby="image-modal"
          aria-describedby="modal-showing-full-image"
          sx={{
            zIndex: 9000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              maxWidth: "90%",
              width: "auto",
              maxHeight: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              overflow: "auto",
            }}
          >
            <img
              src={selectedImage?.imgurl}
              alt="Full size"
              style={{ width: "100%", height: "auto", maxWidth: 600 }}
            />
          </Box>
        </Modal>
      </Box>
    </Modal>
  );
};

export default TopupModal;
