import { IconButton, Typography, Button, Menu } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import OperatorProfile from "./operatorManagement/OperatorProfile";
import OperatorDetailsForm from "./operatorManagement/OperatorDetailsForm";
import MenuItem from "@mui/material/MenuItem";
import CustomConfirmationDialog from "./CustomConfirmationDialog";
import { useParams } from "react-router-dom";
import axiosInstance from "../utils/axiosConfig";
import filterEmptyPropertiesRecursively from "../utils/filterEmptyPropertiesRecursively";
import { object } from "yup";
import usePermission from "../hooks/usePermission";
import { notifyToast } from "../utils/notify";

const menuOptions = [
  {
    text: "Delete Operator",
  },
  {
    text: "Block Operator",
  },
];

// container head
const NewOperatorHeader = ({ type, handleCreate }) => {
  // hooks
  const navigate = useNavigate();

  //state
  const [showMenu, setShowMenu] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmBlock, setShowConfirmBlock] = useState(false);

  const open = Boolean(showMenu);

  const permission = usePermission("/operator/:id");

  // funtion

  // Funtion to Trigger Menu
  const handleClick = (event) => {
    setShowMenu(event.currentTarget);
  };

  // Funtion to Close Menu
  const handleClose = () => {
    setShowMenu(null);
  };

  // Displays a confirmation dialog based on the provided event name.
  const triggerOperatorEvent = (name) => {
    switch (name) {
      case "Delete Operator":
        setShowConfirmDelete(true);
        break;
      case "Block Operator":
        setShowConfirmBlock(true);
        break;
      default:
        break;
    }
  };

  // Funtion to Delete Operator by Id
  const deleteOpertorById = () => {};

  // Funtion to Block Operator by Id
  const blockOperatorById = () => {};

  //Funtion to handle Update
  const handleUpdate = () => {};

  // css
  const iconCss = {
    fill: "#000",
    width: "32px",
    height: "22px",
    boxSizing: "border-box",
  };

  const buttonStyles = {
    border: "1px solid #d9d9da",
    borderRadius: "10px",
    padding: "5px",
  };

  const createButtonStyles = {
    width: "150px",
    height: "32px",
    color: "#fff",
    backgroundColor: "#000",
    "&:hover": {
      backgroundColor: "#000",
    },
  };

  return (
    <Stack
      direction={"row"}
      width={"100%"}
      alignItems={"center"}
      justifyContent={"space-between"}
      boxSizing={"border-box"}
    >
      <IconButton
        sx={buttonStyles}
        onClick={() => {
          // Using the navigate hook to go back
          navigate(-1);
        }}
      >
        <ArrowBackIcon sx={iconCss} />
      </IconButton>

      <Stack direction={"row"} alignItems={"center"}>
        {permission?.UPDATE && (
          <Button sx={createButtonStyles} onClick={handleCreate}>
            {type === "new" ? "Create" : "Update"}
          </Button>
        )}
        {/* {type !== "new" && (
          <div style={{ position: "relative" }}>
            <IconButton onClick={handleClick}>
              <MoreVertIcon sx={iconCss} />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={showMenu}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: -5,
                horizontal: 120,
              }}
              getContentAnchorEl={null}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {menuOptions.length > 0 &&
                menuOptions.map((res) => (
                  <MenuItem
                    key={res?.text}
                    onClick={() => {
                      triggerOperatorEvent(res.text);
                    }}
                  >
                    {res?.text ?? ""}
                  </MenuItem>
                ))}
            </Menu>
          </div>
        )} */}
      </Stack>

      <CustomConfirmationDialog
        open={showConfirmDelete}
        setOpen={setShowConfirmDelete}
        message={`Are you sure you want to Delete this account?`}
        // blockById={blockDriverByID}
        status="delete"
      />

      <CustomConfirmationDialog
        open={showConfirmBlock}
        setOpen={setShowConfirmBlock}
        message={`Are you sure you want to Block this account?`}
        // blockById={blockDriverByID}
        status="block"
      />
    </Stack>
  );
};

const intialOperatorDetails = {
  operatorName: "",
  operatorNumber: "",
  operatorEmail: "",
  full_name: "",
  address: {
    id: "",
    "House number": "",
    Barangay: "",
    City: "",
    "Postal code": "",
  },
};

const OperatorDetails = () => {
  // state
  const [operatorDetails, setOperatorDetails] = useState(intialOperatorDetails);

  const navigate = useNavigate();

  const { id } = useParams();

  const [updateOperatorDetails, setUpdateOperatorDetails] = useState(
    intialOperatorDetails
  );

  const [profilePic, setProfilePic] = useState({
    profilePic: "",
    profile_pic_id: "",
  });

  // function
  const handleOperatorDetailsChange = (name, value) => {
    // keep track of update
    setUpdateOperatorDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    setOperatorDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleAddressChange = (name, value) => {
    // keep track of update
    setUpdateOperatorDetails((prevDetails) => ({
      ...prevDetails,
      address: {
        ...prevDetails.address,
        [name]: value,
      },
    }));

    setOperatorDetails((prevDetails) => ({
      ...prevDetails,
      address: {
        ...prevDetails.address,
        [name]: value,
      },
    }));
  };

  const handleUploadFileToApi = (file) => {
    const apiUrl = "/utils/file/";
    const formData = new FormData();

    const options = {
      headers: {
        "Content-Type": "multipart/form-data", // This is important for form data
      },
    };
    formData.append("file", file);
    formData.append("folder", "public/");

    axiosInstance.post(apiUrl, formData, options).then((res) => {
      setProfilePic((prev) => ({ ...prev, profile_pic_id: res.data.data.id }));
    });
  };

  // config
  const addressConfig = {
    labeltext: "Address",
    name: ["House number", "Barangay", "City", "Postal code"],
    value: operatorDetails.address,
    placeholder: ["House number", "Barangay", "City", "Postal code"],
    type: {
      "House number": "text",
      Barangay: "text",
      City: "text",
      "Postal code": "text",
    },
    handleChange: handleAddressChange,
  };

  //css
  const containerCss = {
    width: "1025px",
    m: "1.5rem auto",
    paddingBottom: "15px",
  };

  const titleStyle = {
    fontFamily: "Proxima Nova",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    color: "#000",
    m: "25px 0",
  };

  const getOperatorDetailsById = async () => {
    const url = `/crm/operator/${id}/`;
    try {
      const response = await axiosInstance.get(url);

      if (response?.data?.data) {
        const operatordetails = response?.data?.data ?? {};
        setProfilePic((prev) => ({
          ...prev,
          profilePic: operatordetails?.profile_pic_url ?? "",
        }));
        setOperatorDetails({
          operatorName: operatordetails?.operator_name ?? "",
          operatorNumber: operatordetails?.phone?.slice(3) ?? "",
          operatorEmail: operatordetails?.email ?? "",
          address: {
            id: operatordetails?.address?.id,
            "House number": operatordetails.address?.house_number ?? "",
            Barangay: operatordetails.address?.barangay ?? "",
            City: operatordetails.address?.city ?? "",
            "Postal code": operatordetails.address?.postal_code ?? "",
          },
        });
      }
    } catch (error) {
      notifyToast.error("Error", error);
    }
  };

  // validation funtion
  const validateEmail = (email) => {
    const emailPattern = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    // const emailIsValid = emailPattern.test(email);
    const emailIsValid = emailPattern.test(email);
    return emailIsValid;
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\d{10}$/;
    const numberIsValid = phoneNumberPattern.test(phoneNumber);
    return numberIsValid;
  };

  const validateInput = (value, type) => {
    switch (type) {
      case "email":
        return validateEmail(value);
      case "phoneNumber":
        return validatePhoneNumber(value);
      default:
        return false;
    }
  };

  const handleUpdate = async () => {
    const operatorEmailValid = validateInput(
      operatorDetails.operatorEmail,
      "email"
    );
    const operatorNumberValid = validateInput(
      operatorDetails.operatorNumber,
      "phoneNumber"
    );

    if (!operatorEmailValid) {
      notifyToast.error("Error", "Invalid email format");
      return;
    }

    if (!operatorNumberValid) {
      notifyToast.error("Error", "Invalid phone number format");
      return;
    }

    const updateobj = {
      profile_pic_id: profilePic?.profile_pic_id ?? "",
      operator_name: updateOperatorDetails?.operatorName ?? "",
      phone: updateOperatorDetails?.operatorNumber
        ? "+63" + updateOperatorDetails?.operatorNumber
        : "",
      email: updateOperatorDetails?.operatorEmail ?? "",
    };

    const address = {
      id: operatorDetails?.address?.id,
      house_number: updateOperatorDetails?.address["House number"] ?? "",
      barangay: updateOperatorDetails?.address?.Barangay ?? "",
      city: updateOperatorDetails?.address?.City ?? "",
      postal_code: updateOperatorDetails?.address["Postal code"] ?? "",
    };

    const filterAddress = filterEmptyPropertiesRecursively(address);

    if (Object.entries(filterAddress).length > 1) {
      updateobj["address"] = address;
    }

    const filterData = filterEmptyPropertiesRecursively(updateobj);

    if (Object.entries(filterData).length === 0) {
      return;
    }

    const url = `/crm/operator/${id}/`;

    try {
      await axiosInstance.patch(url, filterData).then(() => {
        getOperatorDetailsById();
        notifyToast.success(
          "Success",
          "Operator Details Updated Successfully!"
        );
        navigate("/operator-management");
        setUpdateOperatorDetails(intialOperatorDetails);
        if (profilePic.profile_pic_id) {
          setProfilePic((prev) => ({ ...prev, profile_pic_id: "" }));
        }
      });
    } catch (error) {
      notifyToast.error("Error", error);
    }
  };

  useEffect(() => {
    getOperatorDetailsById();
  }, []);

  return (
    <Box sx={containerCss}>
      <NewOperatorHeader type={"update"} handleCreate={handleUpdate} />

      <Typography sx={titleStyle}>Operator Details</Typography>

      <OperatorProfile
        profilePic={profilePic}
        setProfilePic={setProfilePic}
        renamebtntext={"Update"}
        handleUploadFileToApi={handleUploadFileToApi}
      />

      <OperatorDetailsForm
        handleAddressChange={handleAddressChange}
        handleOperatorDetailsChange={handleOperatorDetailsChange}
        operatorDetails={operatorDetails}
        addressConfig={addressConfig}
      />
    </Box>
  );
};

export default OperatorDetails;
