import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import removeSlugFromString from "../utils/removeSlugFromString";
import axiosInstance from "../utils/axiosConfig";
import filterEmptyPropertiesRecursively from "../utils/filterEmptyPropertiesRecursively";
import { notifyToast } from "../utils/notify";

const RescheduleAppointmentModel = ({
  showRescheduleAppointment,
  handleRescheduleAppointmentClick,
  reseduleDetails,
  handleTraningCenter,
  setTrainingDetails,
  trainingCenter,
  tranningDetails,
  slotOptions,
  handleSlotOptions,
  getAppointments = () => {},
}) => {
  const [date, setDate] = useState("");

  async function reseduleAppointment(e) {
    if (
      reseduleDetails.id &&
      date &&
      tranningDetails?.training_center_detail_id
    ) {
      const updateDate = {
        date: date ?? "",
        training_center_id: tranningDetails?.training_center_detail_id,
        slot_key: tranningDetails?.slot_key,
      };

      let filterData = filterEmptyPropertiesRecursively(updateDate);
      await axiosInstance
        .patch(`/crm/appointment/${reseduleDetails.id}/`, filterData)
        .then((_) => {
          notifyToast.success(
            "Success",
            "Appointment Rescheduled Successfully!"
          );
          getAppointments();
          handleRescheduleAppointmentClick(e);
          setDate("");

          setTrainingDetails((prev) => ({
            ...prev,
            training_center_detail_id: "",
            slot_key: "",
          }));
        })
        .catch((error) => {
          notifyToast.error("Error", error);
        });
    }
  }

  return (
    <Modal
      open={showRescheduleAppointment}
      onClose={handleRescheduleAppointmentClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          width: "595px",
          height: "663px",
          borderRadius: "20px",
          background: "#fff",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxSizing: "border-box",
          padding: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "20px auto 0 auto",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Proxima Nova",
              color: "#000",
              fontSize: "20px",
              fontWeight: "600",
              fontStyle: "normal",
              letterSpacing: "0.4px",
            }}
            variant="h2"
          >
            Reschedule appointment
          </Typography>
          <IconButton onClick={handleRescheduleAppointmentClick}>
            <CloseIcon
              sx={{
                color: "#000",
              }}
            />
          </IconButton>
        </Box>

        <Divider
          tick
          sx={{
            background: "#DBDBDB",
            width: "auto",
            margin: "13px auto 0 auto",
            border: "1px solid #DBDBDB",
          }}
        />

        <Stack spacing={2} sx={{ mt: "12px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Proxima Nova",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "0.32px",
                textTransform: "capitalize",
              }}
            >{`Driver : ${reseduleDetails.driverName} `}</Typography>
            <Typography
              sx={{
                color: "#F1554C",
                fontFamily: "Proxima Nova",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "0.24px",
                textTransform: "capitalize",
              }}
            >
              {removeSlugFromString(reseduleDetails?.appointmentStatus ?? "")}
            </Typography>
          </Box>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Proxima Nova",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "0.32px",
              textTransform: "capitalize",
            }}
          >{`Phone : ${reseduleDetails.driverNumber} `}</Typography>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Proxima Nova",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "0.32px",
              textTransform: "capitalize",
            }}
          >{`Last Appointment : ${reseduleDetails.appointmentDate} `}</Typography>
        </Stack>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            sx={{
              margin: "20px auto 0 auto",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <Stack direction="column" spacing={2}>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Proxima Nova",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
                variant="h2"
              >
                Appointment date
              </Typography>
              <TextField
                type="date"
                onChange={(e) => setDate(e.target.value)}
              />
            </Stack>

            <Box>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Proxima Nova",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
                variant="h2"
              >
                Venu Address
              </Typography>
              <Stack
                spacing={2}
                direction="row"
                sx={{
                  margin: "10px 0",
                }}
              >
                <TextField
                  select
                  value={tranningDetails.training_center_detail_id}
                  name="training_center_address"
                  onChange={handleTraningCenter}
                  fullWidth
                  multiline
                >
                  {trainingCenter.length > 0 &&
                    trainingCenter.map((res, index) => (
                      <MenuItem
                        sx={{ width: "100%" }}
                        key={res.id}
                        value={res.id}
                      >
                        {res.training_center_address}
                      </MenuItem>
                    ))}
                </TextField>
              </Stack>
            </Box>
          </Box>
        </LocalizationProvider>

        <Stack
          direction="row"
          width={"80%"}
          sx={{
            boxSizing: "border-box",
            position: "absolute",
            justifyContent: "space-between",
            bottom: "45px",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleRescheduleAppointmentClick}
            sx={{
              width: "45%",
              height: "50px",
              boxSizing: "border-box",
              padding: "13px 12px",
              border: "1.5px solid #000",
              color: "#000",
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{
              width: "45%",
              height: "50px",
              boxSizing: "border-box",
              padding: "13px 12px",
              border: "1.5px solid #000",
              background: "#000",
              color: "#fff",
              "&:hover": {
                background: "#000",
              },
            }}
            variant="contained"
            onClick={(e) => {
              reseduleAppointment(e);
            }}
          >
            Reschedule
          </Button>
        </Stack>

        <Stack mt={"18px"} spacing={2}>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Proxima Nova",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "normal",
            }}
            variant="h2"
          >
            Slots
          </Typography>
          <TextField
            select
            label="Slots"
            disabled={tranningDetails.auto_appoint || slotOptions.length === 0}
            value={tranningDetails.slot_key}
            name="slot_key"
            onChange={handleSlotOptions}
          >
            {slotOptions.length > 0 &&
              slotOptions.map((res, index) => (
                <MenuItem key={res.slot_key} value={res.slot_key}>
                  {res.render_name}
                </MenuItem>
              ))}
          </TextField>
        </Stack>
      </Box>
    </Modal>
  );
};

export default RescheduleAppointmentModel;
