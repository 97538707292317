import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import DataTable from "./DataTable";
import { formatTimeDifference } from "../utils/convertTimestampTo12HourFormat";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axiosInstance from "../utils/axiosConfig";
import AddRemarkModel from "./AddRemarkModel";
import { StyledMenu } from "./Customer";
import DeleteIcon from "@mui/icons-material/Delete";
import documentAddSvg from "../assets/DocumentAdd.svg";
import CustomConfirmationDialog from "./CustomConfirmationDialog";
import filterEmptyPropertiesRecursively from "../utils/filterEmptyPropertiesRecursively";
import axios from "axios";
import useDebounce from "../hooks/useDebounce";
import SwipeableDrawerComponent from "./SwipeableDrawerComponent ";
import DetailsSidePanel from "./DetailsSidePanel";
import removeSlugFromString from "../utils/removeSlugFromString";
import { useDispatch, useSelector } from "react-redux";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import {
  clearAllFilters,
  setTicketRaiseDate,
  setTicketRaised,
  setTicketResolveDate,
  setTicketResolved,
  setTicketStatus,
} from "../state/ticketsSlice";
import { formatDateNew } from "../utils/formateDate";
import CustomFilter, {
  SortPanel,
} from "./reusableComponents/Filter/CustomFilter";
import SearchActionComponent from "./reusableComponents/SearchActionComponent";
import styled from "@emotion/styled";
import TicketsSidePanel from "./TicketsSidePanel";
import QuickFilter from "./QuickFilter";
import usePermission from "../hooks/usePermission";
import { useSettings } from "../hooks/useSettings";
import FilteredResults from "./FilteredResults";
import { removeQuery, setQuery } from "../utils/queryHelpers";
import { bookingIdObj, commonSortOptions } from "../const/sort";
import useQueryParams from "../hooks/useQueryParams ";
import CommonTableHead from "./CommonTableHead";
import { CustomMenu } from "./Menu";
import { notifyToast } from "../utils/notify";

// tooltips
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#000",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#000",
    maxWidth: 500,
  },
}));

// menu component
const TicketsMenu = ({
  open,
  anchorEl,
  params,
  handleClick,
  handleClose,
  setshowAddRemark,
  handleConfirmationDailog,
  setRemarkData,
  permissions,
}) => {
  const MenuIteamTextStyle = {
    color: "#000",
    fontFamily: "Proxima Nova",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "175%",
  };

  return (
    <>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => {
          e.stopPropagation();

          setRemarkData({
            bookingId: params?.row?.booking_id ?? "",
            ticketId: params?.row?.ticket_id ?? "",
            name: params?.row?.full_name ?? "",
            createdAt: params?.row?.created_at ?? "",
            status: params?.row?.status ?? "",
            description: params?.row?.description ?? "",
            subject: params?.row?.subject ?? "",
            remarks: params?.row?.remarks ?? "",
            id: params?.id,
          });

          handleClick(e);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MoreVertIcon
          sx={{
            color: "000",
          }}
        />
      </IconButton>
      <CustomMenu
        id="demo-positioned-menu"
        anchorEl={anchorEl}
        open={anchorEl ? true : false}
        onClose={handleClose}
      >
        {permissions?.UPDATE && (
          <MenuItem>
            {" "}
            <Button
              disableRipple
              disableTouchRipple
              sx={{
                color: "#000",
                fontFamily: "Proxima Nova",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "175%",
              }}
              variant="text"
              startIcon={<img src={documentAddSvg} alt="document add img" />}
              onClick={() => {
                setshowAddRemark(true);
                handleClose();
              }}
            >
              <Typography sx={MenuIteamTextStyle}>Add remarks</Typography>
            </Button>
          </MenuItem>
        )}
        {permissions?.DELETE && (
          <MenuItem>
            <Button
              disableRipple
              disabled
              sx={{
                color: "#000",
                fontFamily: "Proxima Nova",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "175%",
                "&:hover": {
                  background: "white",
                },
                "&.Mui-disabled": {
                  cursor: "not-allowed",
                  color: "gray",
                },
              }}
              variant="text"
              startIcon={<DeleteIcon />}
              onClick={() => {
                handleConfirmationDailog("delete", true);
                handleClose();
              }}
            >
              <Typography sx={MenuIteamTextStyle}>Delete</Typography>
            </Button>
          </MenuItem>
        )}
      </CustomMenu>
    </>
  );
};

// main component
const Tickets = () => {
  const [isLoading, setisLoading] = useState(false);

  const { searchParams, setSearchParams, getQuery } = useQueryParams();

  const { pathname } = useLocation();
  const [selectedName, setSelectedName] = useState("");
  const [activeButton, setActiveButton] = useState("all");
  const [ticketData, setTicketData] = useState([]);
  const [showAddRemark, setShowAddRemark] = useState(false);
  const [remarkData, setRemarkData] = useState({});
  const [showFilter, setShowFilter] = useState({ right: false });
  const location = useLocation();
  const permission = usePermission();
  const [confirmationDailog, setConfirmationDailg] = useState({
    delete: false,
  });
  const { timezone, formatDate, dateformat, timeFormat } = useSettings();

  const [sort, setSort] = useState({
    bookingId: getQuery("booking_id_sort") ?? "",
    ticketsId: getQuery("ticket_id_sort") ?? "",
    dateAndTime: getQuery("date_and_time_sort") ?? "",
  });

  // pagination state

  const [showSort, SetShowSort] = useState({ right: false });

  const ticketidparam = searchParams.get("ticketid");
  const [ticketParamId, setTicketParamId] = useState(
    location?.state?.id || ticketidparam || ""
  );

  const limit = parseInt(getQuery("limit"), 15) || 15;
  const page = parseInt(getQuery("page"), 0) || 0;

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: limit || 15,
    page: page || 0,
  });

  const [totalCount, setTotalCount] = useState(0);

  // search state
  const [search, setSearch] = useState("");
  // search debounce
  const searchDebounce = useDebounce(search, 500);

  const [currentState, setCurrentState] = useState("");

  // accessing tickets state from rudex
  const tickets = useSelector((state) => state.ticketsFilter);

  // using dispatch to trigger action
  const dispatch = useDispatch();

  // ticket id
  const idParam = searchParams.get("id");
  const [ticketId, setTicketId] = useState("");

  // menu state
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // confirmation dailog state
  const [confirmDelete, setConfirmDelete] = useState(false);

  // details side panel state
  const detailSidePanel = searchParams.get("BookingDrawer");
  const ticketSidePanel = searchParams.get("TicketDrawer");
  const [showDetailSidePanel, setShowDetailSidePanel] = useState({
    right: detailSidePanel || false,
  });

  const [showTicketSidePanel, setShowTicketSidePanel] = useState({
    right: ticketSidePanel || false,
  });

  const [detailsID, setDetailsID] = useState(idParam || "");

  const [quickfilter, setQuickFilter] = useState(() => {
    if (!location?.state?.id) {
      return ["INITIATED", "UNDER_REVIEW", "REOPENED"];
    } else {
      return [];
    }
  });

  // ------------ funtion --------

  //checkbox handling function
  const handleTicketStatus = (e) => {
    const ticketStatus = [...quickfilter];
    if (ticketStatus.includes(e.target.value)) {
      ticketStatus.splice(ticketStatus.indexOf(e.target.value), 1);
    } else {
      ticketStatus.push(e.target.value);
    }
    setQuickFilter(ticketStatus);
  };

  const handleConfirmationDailog = (name, value) => {
    setConfirmationDailg((prev) => ({ ...prev, [name]: value }));
  };

  // mui menu handlers
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Gets the corresponding label for a given status value
  const getStatusLabel = (statusValue) => {
    switch (statusValue) {
      case "INITIATED":
        return {
          text: "initiated",
          color: "#043A87",
          background: "rgba(4, 58, 135, 0.10)",
        };
      case "UNDER_REVIEW":
        return {
          text: "under review",
          color: "#FFC940",
          background: "rgba(255, 201, 64, 0.09)",
        };
      case "COMPLETED":
        return {
          text: "resolved",
          color: "#F98244",
          background: "rgba(249, 130, 68, 0.10)",
        };
      case "REOPENED":
        return {
          text: "reopened",
          color: "#043A87",
          background: "rgba(4, 58, 135, 0.10)",
        };
      default:
        return "unknown"; // Default label if the statusValue doesn't match any case
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Funtion to Builds and returns a query
  const buildQueryString = (download = false) => {
    const queryParameters = [];

    if (searchDebounce && search) {
      queryParameters.push(`search=${searchDebounce}`);
    }

    if (ticketParamId) {
      setQuery("ticketid", ticketParamId, setSearchParams);
      queryParameters.push(`rider_id=${location?.state?.id}`);
    }

    if (quickfilter.length > 0 && !location?.state?.id) {
      const statusQueryParams = quickfilter.map((status) => `status=${status}`);

      queryParameters.push(statusQueryParams.join("&"));
    }

    if (tickets.ticketRaised) {
      queryParameters.push(`ticket_raised=${tickets.ticketRaised}`);
    }

    if (sort?.bookingId) {
      queryParameters.push(`booking_id_sort=${sort?.bookingId}`);
    }
    if (sort?.ticketsId) {
      queryParameters.push(`ticket_id_sort=${sort?.ticketsId}`);
    }
    if (sort?.dateAndTime) {
      queryParameters.push(`date_and_time_sort=${sort?.dateAndTime}`);
    }

    if (tickets.ticketResolved) {
      queryParameters.push(`ticket_resolved=${tickets.ticketResolved}`);
    }

    // if (state?.id) {
    //   queryParameters.push(`rider_id=${state?.id}`);
    // }

    if (tickets.ticketRaiseDate.startDate && tickets.ticketRaiseDate.endDate) {
      let startDate = formatDateNew(tickets.ticketRaiseDate?.startDate ?? "");
      let endDate = formatDateNew(tickets.ticketRaiseDate?.endDate ?? "");
      queryParameters.push(
        `ticket_raised_gte=${startDate}&ticket_raised_lte=${endDate}`
      );
    }

    if (
      tickets.ticketResolveDate.startDate &&
      tickets.ticketResolveDate.endDate
    ) {
      let startDate = formatDateNew(tickets.ticketResolveDate?.startDate ?? "");
      let endDate = formatDateNew(tickets.ticketResolveDate?.endDate ?? "");
      queryParameters.push(
        `ticket_resolved_gte=${startDate}&ticket_resolved_lte=${endDate}`
      );
    }

    const queryString =
      queryParameters.length > 0 ? `&${queryParameters.join("&")}` : "";

    return queryString;
  };

  // Funtion Which check pathname set selectName state accordingly
  function checkName(name) {
    switch (name) {
      case "/customer-tickets":
        return "customer";
      case "/driver-tickets":
        return "driver";
      default:
        return "";
    }
  }

  // Function fetch tickets
  const getTickets = async (selectname) => {
    setisLoading(true);
    const query = buildQueryString();
    const userType = selectname === "driver" ? "driver" : "rider";

    try {
      const response = await axiosInstance.get(
        `/crm/${userType}/ticket/?page=${paginationModel.page + 1}&limit=${
          paginationModel.pageSize
        }${query}`
      );
      const records = response?.data?.data?.records ?? [];
      setTicketData(records);
      setTotalCount(response?.data?.data?.total_count ?? 0);
    } catch (error) {
      notifyToast.error("Error", error);
    } finally {
      setisLoading(false);
    }
  };

  // Function fetch tickets
  const getClosedTickets = async (selectname) => {
    const query = buildQueryString();
    const userType = selectname === "driver" ? "driver" : "rider";

    try {
      const response = await axiosInstance.get(
        `/crm/${userType}/ticket/?page=${paginationModel.page + 1}&limit=${
          paginationModel.pageSize
        }${query}`
      );
      const records = response?.data?.data?.records ?? [];
      setTicketData(records);
      setTotalCount(response?.data?.data?.total_count ?? 0);
    } catch (error) {
      notifyToast.error("Error", error);
    }
  };

  // Funtion handle showAddRemark state
  const handleShowAddRemarkClick = () => {
    // passing call back funtion
    setShowAddRemark((prev) => !prev);
  };

  // Function to handle click event by name
  const handleSelectNameClick = (name) => {
    setActiveButton(name);
  };

  // Create a function to handle the DELETE request
  const deleteTicketsCallback = useCallback(async () => {
    const userType = selectedName === "driver" ? "driver" : "rider";
    const apiUrl = `/crm/${userType}/ticket/${remarkData?.id}/`;
    try {
      const response = await axiosInstance.delete(apiUrl);

      // Request was successful (status code 200)
      if (response) {
        notifyToast.success("Success", "Ticket deleted successfully");
        setConfirmDelete(false);
        getTickets(selectedName);
      }
    } catch (error) {
      // An error occurred during the request
      console.error("Error:", error);
    }
  }, [remarkData.id, selectedName]);

  // create function to handle the UPDATE request
  const updateTicketsById = async () => {
    const updateData = {
      remarks: "",
      status: "",
    };

    const selectType = selectedName === "driver" ? "driver" : "rider";

    const Url = `/crm/${selectType}/ticket/${""}/`;
    let filterData = filterEmptyPropertiesRecursively(updateData);

    await axios
      .patch(Url, filterData)
      .then(() => {
        // showSuccssToast only take text as a argument
        notifyToast.success("Success", "Ticket updated successfully!");
        getTickets(selectedName);
      })
      .catch((error) => {
        notifyToast.error("Error", error);
      });
  };

  // function to toggle the state
  const toggleState = () => {
    if (selectedName === "driver") {
      currentState === "customer" && setCurrentState("");
      return;
    } else {
      setCurrentState(currentState === "" ? "customer" : "");
    }
  };

  // mui toggleDrawer funtion for side panel
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowFilter({ ...showFilter, [anchor]: open });
  };

  const toggleDetailSidePanel = (anchor, open) => (event) => {
    if (!open) {
      setDetailsID("");

      if (currentState === "customer") {
        toggleState();
      }
    }
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowDetailSidePanel({ ...showDetailSidePanel, [anchor]: open });
  };

  const toggleTicketSidePanel = (anchor, open) => (event) => {
    if (!open) {
      setDetailsID("");

      if (currentState === "customer") {
        toggleState();
      }
    }
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowTicketSidePanel((prevstate) => ({ ...prevstate, [anchor]: open }));
  };

  // dataGrid colums
  const columns = [
    {
      field: "booking_id",
      headerName: "Booking ID",
      flex: 0.6,
      renderCell: (param) => {
        if (!param.row.booking_id) {
          return "--";
        }

        return (
          <Button
            variant="text"
            onClick={(e) => {
              // toggle details side panel function will return a function
              let callback = toggleDetailSidePanel("right", true);
              // passing the event in callback
              setQuery("BookingDrawer", true, setSearchParams);
              setQuery("id", param?.row?.ride_id, setSearchParams);
              setDetailsID(param?.row?.ride_id ?? "");
              callback(e);
            }}
          >
            <Typography
              sx={{
                color: "#2d5a9b",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "0.26px",
                textTransform: "capitalize",
              }}
            >
              {param.value}
            </Typography>
          </Button>
        );
      },
    },
    {
      field: "ticket_id",
      headerName: "Ticket ID",
      flex: 0.6,
      renderCell: (param) => {
        if (!param.row.ticket_id) {
          return "--";
        }

        return (
          <Button
            variant="text"
            onClick={(e) => {
              setTicketId(param.row.id);
              setDetailsID(param?.row?.ride_id ?? "");
              toggleTicketSidePanel("right", true)(e);
            }}
          >
            <Typography
              sx={{
                color: "#2d5a9b",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "0.26px",
                textTransform: "capitalize",
              }}
            >
              {param.value}
            </Typography>
          </Button>
        );
      },
    },
    {
      field: "full_name",
      headerName: selectedName === "driver" ? "Driver" : "Customer",
      flex: 0.7,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }

        return (
          <BootstrapTooltip title={params.value}>
            <Button
              variant="text"
              sx={{
                color: "#043A87",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "0.26px",
                textTransform: "capitalize",
              }}
              onClick={(e) => {
                // toggle details side panel function will return a function
                let callback = toggleDetailSidePanel("right", true);
                setQuery("BookingDrawer", true, setSearchParams);
                setQuery("id", params?.row?.ride_id, setSearchParams);

                // passing the event in callback
                toggleState();
                setDetailsID(params?.row?.ride_id ?? "");
                callback(e);
              }}
            >
              {params.value}
            </Button>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "nick_name",
      headerName:
        selectedName === "driver" ? "Driver nick name" : "Customer nick name",
      flex: 0.7,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }

        return (
          <BootstrapTooltip title={params.value}>
            <Button
              variant="text"
              sx={{
                color: "#043A87",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "0.26px",
                textTransform: "capitalize",
              }}
              onClick={(e) => {
                // toggle details side panel function will return a function
                let callback = toggleDetailSidePanel("right", true);
                // passing the event in callback
                toggleState();
                setQuery("BookingDrawer", true, setSearchParams);
                setQuery("id", params?.row?.ride_id, setSearchParams);
                setDetailsID(params?.row?.ride_id ?? "");
                callback(e);
              }}
            >
              {params.value}
            </Button>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 0.7,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }
        let phoneNumber = params.value;
        let number = phoneNumber.slice(0, 3) + " " + phoneNumber.slice(3);
        return (
          <Button
            variant="text"
            onClick={(_) => {
              // handleNavigate(params?.row?.driver_account_id);
            }}
          >
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "0.26px",
                textTransform: "capitalize",
              }}
            >
              {number}
            </Typography>
          </Button>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Date and Time",
      flex: 1.1,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }
        let date = formatDate(params.value, timezone, dateformat);
        const time = formatDate(params.value, timezone, timeFormat);
        const obj = getStatusLabel(params.row.status);
        return (
          <Stack direction={"row"} gap={"5px"} alignItems={"flex-end"}>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "0.26px",
              }}
            >
              {date}
            </Typography>

            <Typography
              sx={{
                color: "#6F747E",
                fontFamily: "Proxima Nova",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "0.26px",
              }}
            >
              {time}
            </Typography>

            {params.row.status !== "COMPLETED" && (
              <Box
                sx={{
                  fontSize: "0.7rem",
                  display: "flex",
                  alignItems: "flex-end",
                  padding: "3.116px 7.791px",
                  borderRadius: "31.162px",
                  backgroundColor: obj.background,
                  fontWeight: "bold",
                  gap: "6px",
                }}
              >
                {formatTimeDifference(params?.value, params.row.updated_at)}

                <AccessAlarmIcon
                  sx={{
                    height: "15px",
                    width: "15px",
                    fill: obj.color,
                  }}
                />
              </Box>
            )}
          </Stack>
        );
      },
    },
    {
      field: "subject",
      headerName: "concern",
      flex: 0.7,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }
        return (
          <BootstrapTooltip title={params.value}>
            <Typography
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                overflow: "hidden",
                color: "var(--text-dark, #000)",
                textOverflow: "ellipsis",
                fontFamily: "Proxima Nova",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "0.24px",
              }}
            >
              {removeSlugFromString(params.value)}
            </Typography>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "remarks",
      headerName: "Remarks",
      flex: 0.9,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }
        return (
          <BootstrapTooltip title={params.value}>
            <Typography
              sx={{
                width: "auto",
                maxWidth: "194px",
                height: "25px",
                flexShrink: 0,
                overflow: "hidden",
                color: "var(--text-dark, #000)",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontFamily: "Proxima Nova",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                letterSpacing: "0.24px",
              }}
            >
              {params.value}
            </Typography>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "status",
      headerName: "Ticket Status",
      flex: 0.7,
      renderCell: (params) => {
        const obj = getStatusLabel(params.value);
        return (
          <Button
            variant="text"
            sx={{
              height: "28px",
              boxSizing: "border-box",
              padding: "5px 9px",
              alignItems: "center",
              backgroundColor: obj?.background ?? "",
              borderRadius: "40px",
              gap: "2px",
              textTransform: "capitalize",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Proxima Nova",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "0.24px",
                textTransform: "capitalize",
                color: obj?.color ?? "",
              }}
            >
              {obj?.text ?? ""}
            </Typography>

            <NorthEastIcon
              sx={{
                fill: obj?.color ?? "",
                height: "14px",
                width: "14px",
              }}
            />
          </Button>
        );
      },
    },
    {
      field: "",
      headerName: "",
      minWidth: 5,
      renderCell: (params) => {
        return (
          <TicketsMenu
            open={open}
            anchorEl={anchorEl}
            remarkData={setRemarkData}
            params={params}
            handleClick={handleClick}
            handleClose={handleClose}
            setRemarkData={setRemarkData}
            setshowAddRemark={setShowAddRemark}
            handleConfirmationDailog={handleConfirmationDailog}
            selectName={selectedName}
            permissions={permission}
          />
        );
      },
    },
  ];

  const customeDateHandler = (
    name,
    value,
    setState,
    isoString,
    isDispatch,
    state
  ) => {
    const updatedValue = isoString ? (value ? value.toISOString() : "") : value;

    isDispatch
      ? dispatch(setState({ ...state, [name]: updatedValue }))
      : setState((prev) => ({ ...prev, [name]: updatedValue }));
  };

  const sortFilterConfig = {
    options: [
      {
        ...bookingIdObj,
        state: sort?.bookingId ?? [],
        filterName: "booking_id_sort",
      },
      {
        summaryText: "Ticket id",
        name: "ticketsId",
        state: sort?.ticketsId ?? [],
        options: commonSortOptions,
        filterName: "ticket_id_sort",
      },
      {
        summaryText: "Date & Time",
        name: "dateAndTime",
        state: sort?.dateAndTime ?? [],
        options: commonSortOptions,
        filterName: "date_and_time_sort",
      },
    ],
    handleClearState: () => {},
    // handleFilterState: handleFilterState,
  };

  const tickeRaiseResolvedOptions = [
    { value: "yesterday", label: "Yesterday" },
    { value: "last_week", label: "Last Week" },
    { value: "last_month", label: "Last Month" },
    { value: "last_year", label: "Last Year" },
  ];

  const handleClear = () => {
    dispatch(clearAllFilters());
  };

  const filtersOption = [
    {
      summryText: "Ticket raised",
      state: tickets.ticketRaised,
      filterName: "ticket_raised",
      setState: setTicketRaised,
      options: tickeRaiseResolvedOptions,
      parentDispatch: true,
      date: {
        customDateState: tickets.ticketRaiseDate,
        customDateHeader: "Custom",
        customDateLabel: ["From", "To"],
        filterName: ["ticket_raised_date_gte", "ticket_raised_date_lte"],
        customDateName: ["startDate", "endDate"],
        customeDateHandler,
        setState: setTicketRaiseDate,
        isoString: true,
        isDispatch: true,
      },
    },
    {
      summryText: "Ticket resolved",
      state: tickets.ticketResolved,
      filterName: "ticket_resolved",
      setState: setTicketResolved,
      options: tickeRaiseResolvedOptions,
      parentDispatch: true,
      date: {
        customDateState: tickets.ticketResolveDate,
        customDateHeader: "Custom",
        customDateLabel: ["From", "To"],
        filterName: ["ticket_resolved_date_gte", "ticket_resolved_date_lte"],
        customDateName: ["startDate", "endDate"],
        customeDateHandler,
        setState: setTicketResolveDate,
        isoString: true,
        isDispatch: true,
      },
    },
    {
      summryText: "Ticket Id",
      ignorefilter: true,
      state: ticketParamId,
      setState: setTicketParamId,
      filterName: "ticketid",
      options: [
        {
          value: ticketParamId,
          label: ticketParamId,
        },
      ],
    },
  ];

  // config
  const quickFilterConfig = [
    {
      label: "initiated",
      value: "INITIATED",
    },
    {
      label: "under review",
      value: "UNDER_REVIEW",
    },
    {
      label: "Reopened",
      value: "REOPENED",
    },
    {
      label: "Resolved",
      value: "COMPLETED",
    },
  ];

  // config
  const allowed_sub_sections = permission?.SUB_SECTIONS ?? ["all"];

  const sub_section_to_label = {
    all: "All",
  };

  const tableHeadButtonConfig = allowed_sub_sections?.map((sub_section) => {
    return {
      label: sub_section_to_label[sub_section],
      value: "all",
      totalcount: totalCount,
      onClick: handleSelectNameClick,
    };
  });

  const toggleSortDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    SetShowSort({ ...showSort, [anchor]: open });
  };

  useEffect(() => {
    setSelectedName(checkName(pathname));
    return () => {
      dispatch(clearAllFilters());
    };
  }, [pathname]); // when pathname change we are setting selectname value

  useEffect(() => {
    const ticketRaised = getQuery("ticket_raised");
    if (ticketRaised) {
      dispatch(setTicketRaised(ticketRaised));
    }

    const ticketResolved = getQuery("ticket_resolved");
    if (ticketResolved) {
      dispatch(setTicketResolved(ticketResolved));
    }

    const ticketRaisedDateGte = getQuery("ticket_raised_date_gte");
    const ticketRaiseDateLte = getQuery("ticket_raised_date_lte");
    if (ticketRaisedDateGte || ticketRaiseDateLte) {
      dispatch(
        setTicketRaiseDate({
          startDate: ticketRaisedDateGte ?? null,
          endDate: ticketRaiseDateLte ?? null,
        })
      );
    }

    const ticketReslovedDateGte = getQuery("ticket_resolved_date_gte");
    const ticketResolvedDateLte = getQuery("ticket_resolved_date_lte");
    if (ticketReslovedDateGte || ticketResolvedDateLte) {
      dispatch(
        setTicketResolveDate({
          startDate: ticketReslovedDateGte ?? null,
          endDate: ticketResolvedDateLte ?? null,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (!selectedName) return;
    getTickets(selectedName);
  }, [
    selectedName,
    searchDebounce,
    paginationModel.page,
    paginationModel.pageSize,
    tickets,
    quickfilter,
    ticketParamId,
    sort,
  ]);

  return (
    <Box
      sx={{
        margin: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "22px",
          marginBottom: "25px",
        }}
      >
        <SearchActionComponent
          value={search}
          setState={setSearch}
          toggleSortDrawer={toggleSortDrawer}
          toggleDrawer={toggleDrawer}
          placeholder={`Search Customer , Driver, Booking ID`}
          require={{ search: true, filter: true, download: false, sort: false }}
        />

        <QuickFilter
          handleTicketStatus={handleTicketStatus}
          quickfilter={quickfilter}
          quickFilterConfig={quickFilterConfig}
        />
      </Box>

      {filtersOption && <FilteredResults options={filtersOption} />}

      <CommonTableHead
        totalcount={totalCount}
        ButtonConfig={tableHeadButtonConfig}
        active={activeButton}
      />

      {/* dataGrid mui */}

      <DataTable
        loading={isLoading}
        row={ticketData || []}
        columns={columns || []}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        totalCount={totalCount}
      />

      {/* AddRemark modal */}
      <AddRemarkModel
        showAddRemark={showAddRemark}
        remarkData={remarkData}
        handleAddRemark={handleShowAddRemarkClick}
        bookingID={remarkData.bookingId}
        ticketID={remarkData.ticketId}
        name={remarkData.name}
        selectName={selectedName}
        getTickets={getTickets}
      />

      {/* confirmation  dialog component */}
      <CustomConfirmationDialog
        open={confirmDelete}
        setOpen={setConfirmDelete}
        message="Are you sure you want to delete this account?"
        status="delete"
        deleteById={deleteTicketsCallback}
      />

      <SwipeableDrawerComponent
        isOpen={showTicketSidePanel["right"]}
        onClose={(e) => {
          toggleTicketSidePanel("right", false)(e);
        }}
        anchor="right"
      >
        <TicketsSidePanel
          anchor={"right"}
          DId={detailsID}
          specialFilter={
            selectedName === "customer" ? "rider_ticket" : "driver_ticket"
          }
          toggleDrawer={toggleTicketSidePanel}
          setDriverId={setDetailsID}
          role={currentState}
          ticketId={ticketId}
          ticketsRole={selectedName === "driver" ? "driver" : "rider"}
        />
      </SwipeableDrawerComponent>

      <SwipeableDrawerComponent
        isOpen={showFilter["right"]}
        onClose={(e) => {
          toggleDrawer("right", false)(e);
        }}
        anchor="right"
      >
        <CustomFilter
          filterOptions={filtersOption}
          toggleDrawer={toggleDrawer}
          handleClear={handleClear}
        />
      </SwipeableDrawerComponent>

      <SwipeableDrawerComponent
        isOpen={showDetailSidePanel["right"]}
        onClose={(e) => {
          removeQuery("BookingDrawer", setSearchParams);
          removeQuery("id", setSearchParams);
          toggleDetailSidePanel("right", false)(e);
        }}
        anchor="right"
      >
        <DetailsSidePanel
          anchor={"right"}
          DId={detailsID}
          specialFilter={
            selectedName === "customer" ? "rider_ticket" : "driver_ticket"
          }
          toggleDrawer={toggleDetailSidePanel}
          setDriverId={setDetailsID}
          role={currentState}
        />
      </SwipeableDrawerComponent>

      {showSort.right && (
        <SwipeableDrawerComponent
          isOpen={showSort["right"]}
          onClose={toggleSortDrawer("right", false)}
          anchor="right"
        >
          <SortPanel
            toggleDrawer={toggleSortDrawer}
            sortOptions={sortFilterConfig?.options}
            handleClear={handleClear}
            setState={setSort}
          />
        </SwipeableDrawerComponent>
      )}

      <CustomConfirmationDialog
        open={confirmationDailog?.delete}
        isGroup={true}
        status="delete"
        setState={handleConfirmationDailog}
      />
    </Box>
  );
};

export default Tickets;
