import { Box, Button, Stack, Typography } from "@mui/material";
import SearchActionComponent from "./reusableComponents/SearchActionComponent";
import { useEffect, useState } from "react";
import usePermission from "../hooks/usePermission";
import useDebounce from "../hooks/useDebounce";
import { useSettings } from "../hooks/useSettings";
import { useSearchParams } from "react-router-dom";
import formatNumberShort from "../utils/formatNumberShort";
import { removeQuery, setQuery } from "../utils/queryHelpers";
import axiosInstance from "../utils/axiosConfig";
import DataTable from "./DataTable";
import SwipeableDrawerComponent from "./SwipeableDrawerComponent ";
import DetailsSidePanel from "./DetailsSidePanel";
import { BootstrapTooltip } from "./DetailsSidePanelChart";
import ViewImageModal from "./ViewImageModal";
import CommonTableHead from "./CommonTableHead";
import { notifyToast } from "../utils/notify";
import { lime } from "@mui/material/colors";

const Topup = () => {
  const permissions = usePermission();

  let [searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = useState("");

  const [loading, setisLoading] = useState(false);

  const [viewProofOfPayment, setViewProofOfPayment] = useState({
    view: false,
    url: "",
  });

  const Id = searchParams.get("id");

  const [driverId, setDriverId] = useState(Id);

  const BookingDrawer = searchParams.get("BookingDrawer");

  const [bookingIdDrawer, setBookingIdDrawer] = useState({
    right: BookingDrawer,
  });

  const [activeTab, setActiveTab] = useState(
    permissions?.SUB_SECTIONS?.[0] ?? "All"
  );

  const page = parseInt(searchParams.get("page"), 0) || 0;
  const limit = parseInt(searchParams.get("limit") || "15", 10); // Use 10 as the radix for base-10 numbers

  console.log(typeof page, page, typeof limit, limit, "topup");

  const [topup, setTopup] = useState({
    data: [],
    totalcount: 0,
    paginationModel: {
      page: page || 0,
      pageSize: limit || 15,
    },
  });

  const { dateformat, timezone, formatDate, timeFormat } = useSettings();

  // Debounce
  const searchDebounce = useDebounce(search, 500);

  const handleDownload = () => {
    getTopup("", "download");
  };

  const handleAccountStatus = (status) => {
    setQuery("tab", status, setSearchParams);
    setQuery("page", 0, setSearchParams);
    setQuery("limit", 15, setSearchParams);
    setActiveTab(status);
  };

  const checkQuery = (download) => {
    const query = [];

    if (searchDebounce && search) {
      query.push(`search=${searchDebounce}`);
    }
    if (download) {
      query.push(`is_download=${true}`);
    }

    let qurys = query.length > 1 ? query.join("&") : query.join("");

    return qurys;
  };

  const handleViewProofOfPayment = (key = "view", value = false, obj) => {
    if (obj) {
      setViewProofOfPayment(obj);
    } else {
      setViewProofOfPayment((prev) => ({ ...prev, [key]: value }));
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (!open) {
      setDriverId("");
    }
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setBookingIdDrawer((prev) => ({ ...prev, [anchor]: open }));
  };

  const handlepaginationModel = (data) => {
    setTopup((prev) => ({ ...prev, paginationModel: data }));
  };

  // Fetch
  const getTopup = async (alt = "", status) => {
    setisLoading(true);
    let query = checkQuery(status);

    let url = `/crm/driver/get-topup/?page=${
      topup.paginationModel.page + 1
    }&limit=${topup.paginationModel.pageSize}&${query}`;
    try {
      await axiosInstance.get(url).then((res) => {
        if (status) {
          if (res) {
            notifyToast.success(
              "Success",
              "file has been successfully sent via Email. Check your Email account",
              {
                autoClose: 5000,
              }
            );
          }
          return;
        }

        setTopup((prev) => ({
          ...prev,
          data: res?.data?.data?.records ?? [],
          totalcount: res.data.data.total_count,
        }));
      });
    } catch (error) {
      let message = error?.response?.data?.message;
      if (message) {
        notifyToast.error("Error", error);
      }
    } finally {
      setisLoading(false);
    }
  };

  // config
  const allowed_sub_sections = permissions?.SUB_SECTIONS ?? ["All"];

  const sub_section_to_label = {
    All: "All",
  };

  const tableHeadButtonConfig = allowed_sub_sections?.map((sub_section) => {
    return {
      label: sub_section_to_label[sub_section],
      value: sub_section,
      totalcount: topup?.totalcount ?? 0,
      onClick: handleAccountStatus,
    };
  });

  const columns = [
    {
      field: "transaction_id",
      headerName: "Transcation ID",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            <Button
              id={params.id}
              sx={{
                // color: "#043A87",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                letterSpacing: "0.26px",
                textTransform: "capitalize",
                border: "none",
              }}
              onClick={(e) => {
                e.stopPropagation();

                // if (!params?.id) {
                //   return;
                // }
                // setQuery("BookingDrawer", true, setSearchParams);
                // setQuery("id", params?.id, setSearchParams);
                // const handleEvent = toggleDrawer("right", true);
                // setDriverId(params?.id ?? "");
                // handleEvent(e);
              }}
            >
              {params.value}
            </Button>
          </>
        );
      },
    },
    {
      field: "driver_name",
      headerName: "Driver Name",
      flex: 0.5,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }

        return (
          <BootstrapTooltip title={params?.value}>
            <Typography
              sx={{
                color: "var(--text-dark, #000)",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "0.26px",
                whiteSpace: "normal", // Allow text to wrap
              }}
            >
              {`${params.value}`}
            </Typography>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "transaction_value",
      headerName: "Topup Amount",
      flex: 1.1,

      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }

        return (
          <BootstrapTooltip title={params?.value}>
            <Typography
              sx={{
                color: "var(--text-dark, #000)",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "0.26px",
                whiteSpace: "normal", // Allow text to wrap
              }}
            >
              {`${params.value}`}
            </Typography>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "proof_of_payment_id__url",
      headerName: "Payment Proof",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            id={params.id}
            sx={{
              color: "#043A87",
              fontFamily: "Proxima Nova",
              width: "60px",
              fontSize: "13px",
              letterSpacing: "0.26px",
              textTransform: "capitalize",
              border: "none",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleViewProofOfPayment("", "", {
                view: true,
                url: params.value,
              });
            }}
          >
            View
          </Button>
        );
      },
    },
    {
      field: "created_at",
      headerName: `Topup At(${dateformat})`,
      flex: 0.8,
      renderCell: (params) => {
        if (!params.value && params.value !== 0) {
          return "--";
        }
        let date = formatDate(params.value, timezone, dateformat);
        const time = formatDate(params.value, timezone, timeFormat);

        return (
          <Stack direction={"row"} gap={"5px"} alignItems={"flex-end"}>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "0.26px",
              }}
            >
              {date}
            </Typography>

            <Typography
              sx={{
                color: "#6F747E",
                fontFamily: "Proxima Nova",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "0.26px",
              }}
            >
              {time}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "top_up_by_full_name",
      headerName: "Topup By",
      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }

        return (
          <BootstrapTooltip title={params?.value}>
            <Typography
              sx={{
                color: "var(--text-dark, #000)",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "0.26px",
                whiteSpace: "normal", // Allow text to wrap
              }}
            >
              {`${params.value}`}
            </Typography>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "metadata",
      headerName: "Topup Remark",
      flex: 1,
      renderCell: (params) => {
        if (!params.value?.remark) {
          return "--";
        }

        return (
          <BootstrapTooltip title={params?.value}>
            <Typography
              sx={{
                color: "var(--text-dark, #000)",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "0.26px",
                whiteSpace: "normal", // Allow text to wrap
              }}
            >
              {`${params.value?.remark}`}
            </Typography>
          </BootstrapTooltip>
        );
      },
    },
  ];

  const buttonConfig = {
    active: activeTab,
    totalcount: topup?.totalcount ?? 0,
  };

  useEffect(() => {
    getTopup();
  }, [searchDebounce, topup?.paginationModel]);

  return (
    <Box m="20px">
      <SearchActionComponent
        value={search}
        setState={setSearch}
        require={{ search: true, filter: false, download: true, sort: false }}
        downloadFile={handleDownload}
      />
      <CommonTableHead
        active={buttonConfig?.active}
        ButtonConfig={tableHeadButtonConfig}
        totalcount={buttonConfig?.totalcount}
      />
      <DataTable
        row={topup?.data || []}
        columns={columns}
        sx={{ cursor: "pointer" }}
        alternativeId="transaction_id"
        paginationInnerObject={true}
        paginationModel={topup?.paginationModel}
        loading={loading}
        setPaginationModel={setTopup}
        totalCount={topup?.totalcount}
      />
      <ViewImageModal
        url={viewProofOfPayment?.url}
        open={viewProofOfPayment?.view}
        onClose={handleViewProofOfPayment}
      />
      {bookingIdDrawer?.right && (
        <SwipeableDrawerComponent
          isOpen={bookingIdDrawer?.right}
          anchor="right"
          onClose={(e) => {
            removeQuery("BookingDrawer", setSearchParams);
            removeQuery("id", setSearchParams);
            toggleDrawer("right", false)(e);
          }}
        >
          <DetailsSidePanel
            setDriverId={setDriverId}
            DId={driverId}
            anchor={"right"}
            specialFilter={"both_ticket"}
            toggleDrawer={toggleDrawer}
          />
        </SwipeableDrawerComponent>
      )}
    </Box>
  );
};

export default Topup;
