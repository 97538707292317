import { Box, Button } from "@mui/material";
import React from "react";

import { useParams } from "react-router";
import axiosInstance from "../../utils/axiosConfig";
import filterEmptyPropertiesRecursively from "../../utils/filterEmptyPropertiesRecursively";
import usePermission from "../../hooks/usePermission";
import { toast } from "react-toastify";
import { notifyToast } from "../../utils/notify";

function removeProperties(inputObject) {
  // Create a new object without the specified properties
  const resultObject = Object.fromEntries(
    Object.entries(inputObject).filter(
      ([key]) => key !== "is_verified" && key !== "status"
    )
  );

  return resultObject;
}

const idKeys = [
  "doc1",
  "doc2",
  "doc3",
  "doc4",
  "doc5",
  "doc6",
  "carDoc1",
  "carDoc2",
  "carDoc3",
];

const CommonSaveButton = ({
  body = () => {},
  basicDetails = {},
  DriverDetailsValidationByApi = () => {},
  operatorName,
  operator,
  updateIdName = "",
  idRef,
  title,
  handleLoading = () => {},
  loading = {},
}) => {
  const { id } = useParams();

  const permission = usePermission("/driver/:id");

  const validateEmail = (email) => {
    const emailPattern = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    const emailIsValid = emailPattern.test(email);
    return emailIsValid;
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\d{10}$/;
    const numberIsValid = phoneNumberPattern.test(phoneNumber);
    return numberIsValid;
  };

  const validateData = async () => {
    const checkEmail = validateEmail(basicDetails.email);

    let opertorname;

    const checkNumber = validatePhoneNumber(basicDetails.phoneNumber);

    if (!checkEmail && basicDetails.email) {
      toast.error("Invalid email address!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleLoading(title, false);
      return;
    }

    if (!checkNumber) {
      toast.error("Invalid number format!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleLoading(title, false);
      return;
    }

    if (operatorName) {
      const findOpertorNameById = operator?.find(
        (res) => res.id === operatorName
      );

      opertorname = findOpertorNameById?.operator_name;
    }

    const checkUniqness = await DriverDetailsValidationByApi({
      ...(opertorname && { operator_name: opertorname }),
      phone: `+63${basicDetails?.phoneNumber ?? ""}`,
      ...(basicDetails?.email && { email: basicDetails?.email ?? "" }),
      ...(id && { driver_id: id }),
    });

    if (!checkUniqness) {
      handleLoading(title, false);
      return;
    }

    return true;
  };

  const handleSave = async (idRef) => {
    handleLoading(title, true);

    if (updateIdName === "basicDetails") {
      const res = await validateData().then((res) => res);

      if (!res) return;
    }

    try {
      const url = `/crm/driver/update-onboarding-step-data/${id}/`;

      const checkBody = body();

      if (!checkBody) return;

      const saveBody = {
        body: [removeProperties(filterEmptyPropertiesRecursively(checkBody))],
      };
      const response = await axiosInstance.patch(url, saveBody);

      const data = Object.entries(idRef.current)?.reduce((acc, [key, res]) => {
        if (response?.data?.data[key]) {
          acc[key] = response?.data?.data[key];
        } else {
          acc[key] = res;
        }
        return acc; // Add this line to return the accumulator
      }, {});

      console.log(data, idRef, "api response and idref");

      idRef.current = data;

      notifyToast.success("Success", "Successfully Saved!");
    } catch (error) {
      notifyToast.error("Error", error);
    } finally {
      setTimeout(() => {
        handleLoading(title, false);
      }, 500);
    }
  };

  const containerStyle = {
    width: "100%",
    padding: "0.9rem 1.5rem",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "flex-end",
  };

  const saveButtonStyle = {
    width: "160px",
    backgroundColor: "#000",
    color: "#fff",
    height: "45px",
    borderRadius: "12px",
    "&:hover": {
      backgroundColor: "#000",
    },
  };

  return permission?.UPDATE ? (
    <Box sx={containerStyle}>
      <Button
        disabled={loading[title] ? true : false}
        variant="contained"
        onClick={() => {
          handleSave(idRef);
        }}
        sx={saveButtonStyle}
      >
        Save
      </Button>
    </Box>
  ) : (
    <></>
  );
};

export default CommonSaveButton;
