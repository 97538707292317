import React, { useEffect, useRef, useState } from "react";
import { Box, Button, MenuItem, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import RideStatus from "../RideStatus";
import DetailsSidePanel from "../DetailsSidePanel";
import useDebounce from "../../hooks/useDebounce";
import formatNumberShort from "../../utils/formatNumberShort";
import axiosInstance from "../../utils/axiosConfig";
import { truncateWithEllipsis } from "../../utils/removeSlugFromString";
import SearchActionComponent from "../reusableComponents/SearchActionComponent";
import SwipeableDrawerComponent from "../SwipeableDrawerComponent ";
import { BootstrapTooltip } from "../DetailsSidePanelChart";
import usePermission from "../../hooks/usePermission";

import CustomFilter from "../reusableComponents/Filter/CustomFilter";
import FilteredResults from "../FilteredResults";
import { useSettings } from "../../hooks/useSettings";
import { StyledMenu } from "../Customer";
import MuiIconButton from "../uicomponents/MuiIconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomConfirmationDialog from "../CustomConfirmationDialog";
import DataTable, {
  calculateMinWidth,
  CustomLoadingOverlay,
} from "../DataTable";
import { removeQuery, setQuery } from "../../utils/queryHelpers";
import FlagReviewStatus from "./FlagReviewStatus";
import useQueryParams from "../../hooks/useQueryParams ";
import CommentSidebar from "../comment/CommentSidebar";
import CommonTableHead from "../CommonTableHead";
import { CustomMenu } from "../Menu";
import { notifyToast } from "../../utils/notify";

const StatusIndicator = ({ text, fill }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      gap: "12px",
    }}
  >
    <Typography>{text}</Typography>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle cx="10" cy="10" r="10" fill={fill} />
    </svg>
  </Box>
);

function Action({
  handleConformationDailog,
  params,
  open,
  handleClick,
  anchorEl,
  setRiderId,
  setBookingId,
  handleClose,
  permissionsSubSection = {
    DELETE: true,
    UPDATE: true,
  },
}) {
  // css
  const menuIteamBtnStyle = {
    color: "#000",
    fontFamily: "Proxima Nova",
    fontSize: "12px",
    width: "100%",
    fontStyle: "normal",
    justifyContent: "flex-start",
    fontWeight: 500,
    lineHeight: "175%",
  };

  return (
    <>
      <MuiIconButton
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e, params.row.id);
          setRiderId(params.row.id);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MoreVertIcon
          sx={{
            color: "#000",
          }}
        />
      </MuiIconButton>
      <>
        <CustomMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          ModalProps={{
            style: {
              zIndex: 1200,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          {permissionsSubSection?.UPDATE && (
            <MenuItem>
              <Button
                sx={menuIteamBtnStyle}
                disableRipple
                disableFocusRipple
                disableElevation
                variant="text"
                // startIcon={<DeleteIcon sx={{ color: "#000" }} />}
                onClick={(e) => {
                  e.stopPropagation();
                  setBookingId(params?.row?.booking_id);
                  handleClose();
                  handleConformationDailog("flagged", true);
                }}
              >
                UNFLAG
              </Button>
            </MenuItem>
          )}
        </CustomMenu>
      </>
    </>
  );
}

// main component
const TripsFlagged = () => {
  const [isLoading, setisLoading] = useState(false);
  const remarkRef = useRef("");

  const [trips, setTrips] = useState([]);

  const permissions = usePermission();
  // Loader state
  const [loader, setLoader] = useState(false);
  const { timezone, formatDate, dateformat, timeFormat } = useSettings();
  const { getQuery } = useQueryParams();
  const [isReviewed, setIsReviewed] = useState(
    getQuery("is_reviewed") ? Boolean(getQuery("is_reviewed")) : false
  );

  const [comments, setComments] = useState({
    intialData: [],
    data: [],
    bookingId: "",
    rideId: "",
    show: {
      right: false,
    },
  });

  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const BookingDrawer = searchParams.get("BookingDrawer");
  const [showDriverDetails, setDriverDetails] = useState({
    right: BookingDrawer || false,
  });

  const Id = searchParams.get("id");
  const customerDrawer = searchParams.get("customerdrawer");
  const [driverId, setDriverId] = useState(Id || "");
  const [riderId, setRiderId] = useState("");
  const [bookingId, setBookingId] = useState("");

  const [showCustomerFilter, setCustomerFilter] = useState({
    right: customerDrawer || false,
  });

  const [showFilter, setShowFilter] = useState({
    right: false,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [conformationDialog, setConformationDialog] = useState({
    flagged: false,
  });

  const downloadFile = async () => {
    let qurys = checkQuery(true) ?? [];
    let url =
      qurys.length > 0
        ? `/crm/flag/rides/?page=${paginationModel.page + 1}&limit=${
            paginationModel.pageSize
          }&${qurys}&is_download=true`
        : `/crm/flag/rides/?page=${paginationModel.page + 1}&limit=${
            paginationModel.pageSize
          }&is_download=true`;

    try {
      await axiosInstance.get(url);
      notifyToast.success(
        "Success",
        "File has been successfully sent via Email. Check your Email account"
      );
    } catch (error) {
      let message = error?.response?.data?.message;
      if (message) {
        notifyToast.error("Error", message);
      }
      console.log("Error while downloading flagged trips", error);
    }
  };
  // dataGrid columns
  const columns = [
    // {
    //   field: "",
    //   headerName: "",
    //   flex: 0.1,
    //   renderCell: (params) => <RideStatus {...params} />,
    // },
    {
      field: "booking_id",
      headerName: "Booking ID",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            <Button
              id={params.id}
              sx={{
                color: "#043A87",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                letterSpacing: "0.26px",
                textTransform: "capitalize",
                border: "none",
              }}
              onClick={(e) => {
                e.stopPropagation();

                if (!params?.id) {
                  return;
                }
                setQuery("BookingDrawer", true, setSearchParams);
                setQuery("id", params?.id, setSearchParams);
                const handleEvent = toggleDrawer("right", true);
                setDriverId(params?.id ?? "");
                handleEvent(e);
              }}
            >
              {params.row.booking_id}
            </Button>
          </>
        );
      },
    },
    {
      field: "driver_phone",
      headerName: "Phone NO",
      flex: 0.6,
      renderCell: (params) => {
        if (typeof params?.value !== "string") {
          return "--";
        }

        let phoneNumber = params?.value;
        let number = phoneNumber.slice(0, 3) + " " + phoneNumber.slice(3);
        return (
          <>
            <Button
              id={params.id}
              sx={{
                color: "#043A87",
                textOverflow: "ellipsis",
                width: "110px",
                fontWeight: "500",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                textTransform: "capitalize",
                border: "none",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleNavigate(params?.row?.driver_id ?? "");
              }}
            >
              {number}
            </Button>
          </>
        );
      },
    },
    {
      field: "driver_full_name",
      headerName: "Driver",
      flex: 0.6,
      renderCell: (params) => {
        if (!params?.value) {
          return "--";
        }

        return (
          <>
            <BootstrapTooltip title={params?.value}>
              <Button
                id={params.id}
                sx={{
                  border: "none",
                  display: "flex",
                  justifyContent: "flex-start",
                  overflow: "hidden",
                  width: "100%",
                }}
                onClick={() => {
                  handleNavigate(params?.row?.driver_id ?? "");
                }}
              >
                <Typography
                  sx={{
                    color: "#043A87",
                    fontFamily: "Proxima Nova",
                    // overflow: "hidden",
                    // textOverflow: "ellipsis",
                    fontSize: "13px",
                    textTransform: "capitalize",
                    letterSpacing: "0.26px",
                    fontWeight: "500",
                  }}
                >
                  {params?.value}
                </Typography>
              </Button>
            </BootstrapTooltip>
          </>
        );
      },
    },
    {
      field: "driver_nick_name",
      headerName: "Driver nick name",
      flex: 0.6,
      renderCell: (params) => {
        if (!params?.value) {
          return "--";
        }

        return (
          <>
            <BootstrapTooltip title={params?.value}>
              <Button
                id={params.id}
                sx={{
                  border: "none",
                  display: "flex",
                  justifyContent: "flex-start",
                  overflow: "hidden",
                  width: "100%",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: "vertical",
                }}
                onClick={() => {
                  handleNavigate(params?.row?.driver_id ?? "");
                }}
              >
                <Typography
                  sx={{
                    color: "#043A87",
                    fontFamily: "Proxima Nova",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "13px",
                    textTransform: "capitalize",
                    letterSpacing: "0.26px",
                    fontWeight: "500",
                  }}
                >
                  {truncateWithEllipsis(params?.value, 15)}
                </Typography>
              </Button>
            </BootstrapTooltip>
          </>
        );
      },
    },
    {
      headerName: "Customer",
      field: "rider_full_name",
      flex: 0.6,
      renderCell: (params) => {
        if (!params?.value) {
          return "--";
        }

        return (
          <>
            <BootstrapTooltip title={params?.value}>
              <Button
                id={params.id}
                sx={{
                  color: "#043A87",
                  fontFamily: "Proxima Nova",
                  fontSize: "13px",
                  border: "none",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setQuery("customerdrawer", true, setSearchParams);
                  setQuery("id", params?.id, setSearchParams);
                  let callBack = toggleCustomerFilterDrawer("right", true);
                  setDriverId(params?.id ?? "");
                  callBack(e);
                }}
              >
                <Typography
                  sx={{
                    color: "#043A87",
                    fontFamily: "Proxima Nova",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "13px",
                    textTransform: "capitalize",
                    letterSpacing: "0.26px",
                    fontWeight: "500",
                  }}
                >
                  {truncateWithEllipsis(params?.value, 20)}
                </Typography>
              </Button>
            </BootstrapTooltip>
          </>
        );
      },
    },
    {
      headerName: "Customer nick name",
      field: "rider_nick_name",
      flex: 0.6,
      renderCell: (params) => {
        if (!params?.value) {
          return "--";
        }

        return (
          <>
            <BootstrapTooltip title={params?.value}>
              <Button
                id={params.id}
                sx={{
                  color: "#043A87",
                  fontFamily: "Proxima Nova",
                  fontSize: "13px",
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "flex-start",
                  border: "none",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setQuery("customerdrawer", true, setSearchParams);
                  setQuery("id", params?.id, setSearchParams);
                  let callBack = toggleCustomerFilterDrawer("right", true);
                  setDriverId(params?.id ?? "");
                  callBack(e);
                }}
              >
                <Typography
                  sx={{
                    color: "#043A87",
                    fontFamily: "Proxima Nova",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "13px",
                    textTransform: "capitalize",
                    letterSpacing: "0.26px",
                    fontWeight: "500",
                  }}
                >
                  {truncateWithEllipsis(params?.value, 20)}
                </Typography>
              </Button>
            </BootstrapTooltip>
          </>
        );
      },
    },
    {
      field: "cab_type",
      headerName: "Cab type",
      flex: 0.6,
      renderCell: (param) => {
        if (!param?.row?.cab_type) {
          return "--";
        }

        return (
          <Typography
            sx={{
              textOverflow: "ellipsis",
              width: "auto",
              overflow: "hidden",
              fontSize: "12px",
              fontStyle: "normal",
              fontFamily: "Proxima Nova",
              whiteSpace: "nowrap",
              fontWeight: "400",
              letterSpacing: "0.24px",
              textAlign: "left",
            }}
          >
            {param.row.cab_type}
          </Typography>
        );
      },
    },
    // {
    //   field: "payment_details.payment_method",
    //   headerName: "Mode",
    //   flex: 0.5,
    //   renderCell: (param) => {
    //     if (!param.row.payment_details?.payment_method) {
    //       return "--";
    //     }

    //     return (
    //       <Button
    //         sx={{
    //           color: "#043A87",
    //         }}
    //         variant="text"
    //         onClick={() => navigate("/customer-payments")}
    //       >
    //         <Typography
    //           sx={{
    //             textOverflow: "ellipsis",
    //             fontSize: "13px",
    //             fontStyle: "normal",
    //             fontFamily: "Proxima Nova",
    //             fontWeight: "500",
    //             color: "#043A87",
    //           }}
    //         >
    //           {param.row.payment_details.payment_method}
    //         </Typography>
    //       </Button>
    //     );
    //   },
    // },
    {
      field: "flag_time",
      headerName: `Flagged At(${dateformat})`,
      flex: 0.8,
      renderCell: (params) => {
        if (!params.value && params.value !== 0) {
          return "--";
        }
        let date = formatDate(params.value, timezone, dateformat);
        const time = formatDate(params.value, timezone, timeFormat);

        return (
          <Stack direction={"row"} gap={"5px"} alignItems={"flex-end"}>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "0.26px",
              }}
            >
              {date}
            </Typography>

            <Typography
              sx={{
                color: "#6F747E",
                fontFamily: "Proxima Nova",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "0.26px",
              }}
            >
              {time}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "flag_reason",
      headerName: "Flagged Reason",
      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }

        return (
          <BootstrapTooltip title={params?.value}>
            <Typography
              sx={{
                color: "var(--text-dark, #000)",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                textAlign: "left",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "0.26px",
                whiteSpace: "normal", // Allow text to wrap
              }}
            >
              {`${params.value}`}
            </Typography>
          </BootstrapTooltip>
        );
      },
    },
    {
      field: "flag_reviewed_by_status",
      headerName: "Review Status",
      flex: 0.6,
      renderCell: (params) => {
        return (
          <>
            <FlagReviewStatus
              id={params.row.id}
              value={
                params?.row?.flag_reviewed_by_name &&
                params?.row?.flag_reviewed_time
                  ? "reviewed"
                  : "pending"
              }
              getAppointments={getTrips}
            />
          </>
        );
      },
    },
    {
      field: "flag_reviewed_time",
      headerName: `Review At(${dateformat})`,
      flex: 0.8,
      renderCell: (params) => {
        if (!params.value && params.value !== 0) {
          return "--";
        }
        let date = formatDate(params.value, timezone, dateformat);
        const time = formatDate(params.value, timezone, timeFormat);

        return (
          <Stack direction={"row"} gap={"5px"} alignItems={"flex-end"}>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "0.26px",
              }}
            >
              {date}
            </Typography>

            <Typography
              sx={{
                color: "#6F747E",
                fontFamily: "Proxima Nova",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "0.26px",
              }}
            >
              {time}
            </Typography>
          </Stack>
        );
      },
    },

    {
      field: "comments",
      headerName: `Comments`,
      flex: 0.8,
      renderCell: (params) => {
        if (!Array.isArray(params?.value)) {
          return "--";
        }

        return (
          <>
            <BootstrapTooltip title={params?.value?.length ?? 0}>
              <Button
                id={params.id}
                sx={{
                  color: "#043A87",
                  fontFamily: "Proxima Nova",
                  fontSize: "13px",
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "flex-start",
                  border: "none",
                }}
                onClick={(e) => {
                  toggleShowComment("right", true)(e);

                  setComments((prev) => ({
                    ...prev,
                    data: params?.value,
                    bookingId: params?.row?.booking_id,
                    ride_id: params?.row?.id,
                  }));
                }}
              >
                <Typography
                  sx={{
                    color: "#043A87",
                    fontFamily: "Proxima Nova",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "13px",
                    textTransform: "capitalize",
                    letterSpacing: "0.26px",
                    fontWeight: "500",
                  }}
                >
                  {params?.value?.length ?? 0}
                </Typography>
              </Button>
            </BootstrapTooltip>
          </>
        );
      },
    },
    {
      field: "flag_reviewed_by_name",
      headerName: "Review By",
      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return "--";
        }

        return (
          <BootstrapTooltip title={params?.value}>
            <Typography
              sx={{
                color: "var(--text-dark, #000)",
                fontFamily: "Proxima Nova",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "0.26px",
                whiteSpace: "normal", // Allow text to wrap
              }}
            >
              {`${params.value}`}
            </Typography>
          </BootstrapTooltip>
        );
      },
    },
    {
      headerName: "Action",
      flex: 0.2,
      renderCell: (params) => (
        <Action
          // handleUserStatus={handleUserStatus}
          params={params}
          handleConformationDailog={handleconformationDialog}
          setRiderId={setRiderId}
          setBookingId={setBookingId}
          open={open}
          handleClick={handleClick}
          anchorEl={anchorEl}
          handleClose={handleClose}
        />
      ),
    },
  ];

  const [search, setSearch] = useState("");

  const specialFilter =
    pathname === "/customer-trips" ? "rider_ticket" : "driver_ticket";

  // Debounce
  const searchDebounce = useDebounce(search, 1500);

  const page = parseInt(searchParams.get("page"), 0) || 0;
  const limit = parseInt(searchParams.get("limit"), 15) || 15;
  // control mui pagination
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: limit || 15,
    page: page || 0,
  });

  // Pagination state
  const [totalCount, setTotalCount] = useState(0);

  const [activeButton, setActiveButton] = useState("Trips");

  const navigate = useNavigate();
  // mui toggleDrawer funtion for side panel
  const toggleDrawer = (anchor, open) => (event) => {
    if (!open) {
      setDriverId("");
    }
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDriverDetails((prev) => ({ ...prev, [anchor]: open }));
  };

  const handleconformationDialog = (name, value) => {
    setConformationDialog((prev) => ({ ...prev, [name]: value }));
  };

  const toggleFilterDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowFilter((prev) => ({ ...prev, [anchor]: open }));
  };

  const toggleShowComment = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setComments((prev) => ({ ...prev, show: { [anchor]: open } }));
  };

  const handleClick = (event, riderId) => {
    setAnchorEl(event.currentTarget);
    setRiderId(riderId);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (id) => {
    if (id) {
      navigate(`/driver/${id}`);
    }
  };
  // Function to handle click event by name
  const handleSelectNameClick = (name) => {
    setActiveButton(name);
  };

  const toggleCustomerFilterDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setCustomerFilter((prev) => ({ ...prev, [anchor]: open }));
  };

  const checkQuery = (download = false) => {
    const query = [];

    if (searchDebounce && search) {
      query.push(`search=${searchDebounce}`);
    }
    if (download) {
      query.push("is_download=true");
    }

    if (isReviewed) {
      query.push(`is_reviewed=${isReviewed}`);
    }

    let qurys = query.length > 1 ? query.join("&") : query.join("");

    return qurys;
  };

  // Funcion to Fetch Trips data from server
  const getTrips = async (qurys = []) => {
    setisLoading(true);
    let url =
      qurys.length > 0
        ? `/crm/flag/rides/?page=${paginationModel.page + 1}&limit=${
            paginationModel.pageSize
          }&${qurys}`
        : `/crm/flag/rides/?page=${paginationModel.page + 1}&limit=${
            paginationModel.pageSize
          }`;
    // passing token in headers

    try {
      // setLoader(true);
      await axiosInstance.get(url).then((res) => {
        setLoader(false);
        setTotalCount(res.data.data.total_count);
        setTrips(res?.data?.data?.records ?? []);

        if (comments?.show) {
          const tripsData = res?.data?.data?.records?.find(
            (trip) => trip?.id === comments?.ride_id
          );

          console.log(tripsData, comments);

          setComments((prev) => ({
            ...prev,
            data: tripsData?.comments ?? [],
          }));
        }
      });
    } catch (error) {
      setLoader(false);
      console.log("Error while Fetching Driver Trips", error);
    } finally {
      setisLoading(false);
    }
  };

  // function sends a PATCH request to update the flag
  const updateFlagStatus = async (id, flag = false) => {
    const is_flagged = false;
    console.log(remarkRef.current);

    const endpoint = `/crm/ride/update/${id}/`;
    const toastMessage = "User Successfully unflagged";

    try {
      const response = await axiosInstance.patch(endpoint, {
        is_flagged,
        unflag_remark: remarkRef.current,
      });

      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }

      remarkRef.current = "";

      getTrips();

      notifyToast.success("Success", toastMessage);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      notifyToast.error(
        "Error",
        "An error occurred while updating the flag status."
      );
    }
  };

  // Function
  // const downloadFile = async () => {
  //   let qurys = checkQuery(true) ?? [];
  //   let url =
  //     qurys.length > 0
  //       ? `/crm/ride/?page=${paginationModel.page + 1}&limit=${
  //           paginationModel.pageSize
  //         }&${qurys}`
  //       : `/crm/ride/?page=${paginationModel.page + 1}&limit=${
  //           paginationModel.pageSize
  //         }`;

  //   try {
  //     // setLoader(true);
  //     await axiosInstance.get(url).then((res) => {
  //
  //     });
  //   } catch (error) {
  //     setLoader(false);
  //     let message = error?.response?.data?.message;
  //
  //     console.log("Error while Fetching Driver Trips", error);
  //   }
  // };

  // config

  // const RideStatusConfig = [
  //   {
  //     text: "First Mile",
  //     fill: "#043A87",
  //   },
  //   {
  //     text: "Ongoing Ride",
  //     fill: "#009D0F",
  //   },
  //   {
  //     text: "Completed Ride",
  //     fill: "#FFC940",
  //   },
  //   {
  //     text: "Cancelled Ride",
  //     fill: "#D1D1D1",
  //   },
  //   {
  //     text: "Driver Not Found",
  //     fill: "red",
  //   },
  // ];

  // config
  const sub_section_to_label = {
    trips: "Trips",
  };
  const handlePagination = (value) => {
    searchParams.set("page", value?.page || 0);
    searchParams.set("limit", value?.pageSize || 10);

    // Set the new search parameters with the replace option
    setSearchParams(searchParams, { replace: true });
    setPaginationModel((prev) => ({ ...prev, ...value }));
  };

  const allowed_sub_sections = permissions?.SUB_SECTIONS ?? ["trips"];

  const tableHeadButtonConfig = allowed_sub_sections?.map((sub_section) => {
    return {
      label: sub_section_to_label[sub_section],
      value: sub_section_to_label[sub_section],
      totalCount,
      onClick: handleSelectNameClick,
    };
  });

  const customerFilterOptions = [
    {
      summryText: "Review Status",
      state: isReviewed,
      filterName: "is_reviewed",
      setState: setIsReviewed,
      options: [
        { label: "Pending", value: "false" },
        { label: "Reviewed", value: "true" },
      ],
    },
  ];

  useEffect(() => {
    let qurys = checkQuery();

    getTrips(qurys);
  }, [
    searchDebounce,
    paginationModel?.page,
    paginationModel?.pageSize,
    isReviewed,
  ]);

  return (
    <>
      {loader ? (
        <div>loading..</div>
      ) : (
        <Box
          sx={{
            margin: "20px",
          }}
        >
          <SearchActionComponent
            value={search}
            setState={setSearch}
            // toggleDrawer={toggleFilterDrawer}
            placeholder={"Search Customer , Driver, Booking ID"}
            require={{
              search: true,
              filter: true,
              download: true,
              // download: pathname !== "/customer-trips" ? true : false,
            }}
            downloadFile={downloadFile}
            toggleDrawer={toggleFilterDrawer}
          />

          <CommonTableHead
            totalcount={totalCount}
            ButtonConfig={tableHeadButtonConfig}
            active={activeButton}
          />

          <DataTable
            ownPaginationHandler={true}
            row={trips || []}
            columns={columns}
            loading={isLoading}
            paginationModel={paginationModel}
            setPaginationModel={handlePagination}
            totalCount={Math.ceil(totalCount)}
          />

          {showDriverDetails["right"] && (
            <SwipeableDrawerComponent
              isOpen={showDriverDetails["right"]}
              anchor="right"
              onClose={(e) => {
                removeQuery("BookingDrawer", setSearchParams);
                removeQuery("id", setSearchParams);
                toggleDrawer("right", false)(e);
              }}
            >
              <DetailsSidePanel
                setDriverId={setDriverId}
                DId={driverId}
                anchor={"right"}
                role={pathname === "/customer-trips" ? "customer" : ""}
                specialFilter={specialFilter}
                toggleDrawer={toggleDrawer}
              />
            </SwipeableDrawerComponent>
          )}

          {showFilter && (
            <SwipeableDrawerComponent
              isOpen={showFilter["right"]}
              anchor="right"
              onClose={toggleFilterDrawer("right", false)}
            >
              <CustomFilter
                filterOptions={customerFilterOptions}
                toggleDrawer={() => toggleFilterDrawer("right", false)}
              />
            </SwipeableDrawerComponent>
          )}

          {showCustomerFilter["right"] && (
            <SwipeableDrawerComponent
              isOpen={showCustomerFilter["right"]}
              anchor="right"
              onClose={(e) => {
                removeQuery("customerdrawer", setSearchParams);
                removeQuery("id", setSearchParams);
                toggleCustomerFilterDrawer("right", false)(e);
              }}
            >
              <DetailsSidePanel
                DId={driverId}
                anchor={"right"}
                toggleDrawer={toggleCustomerFilterDrawer}
                role="customer"
                specialFilter={"rider_ticket"}
                fetchFunction={getTrips}
              />
            </SwipeableDrawerComponent>
          )}

          {comments?.show["right"] && (
            <SwipeableDrawerComponent
              isOpen={comments?.show["right"]}
              anchor="right"
              onClose={(e) => {
                toggleShowComment("right", false)(e);
              }}
            >
              <CommentSidebar
                comments={comments}
                getTrips={getTrips}
                toggleDrawer={toggleShowComment}
                setComments={setComments}
              />
            </SwipeableDrawerComponent>
          )}

          {/* <Box
            sx={{
              width: "auto",
              margin: "auto",
              padding: "25px 0",
            }}
          >
            <Typography>Ride Status</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginTop: "12px",
              }}
            >
              {RideStatusConfig.length > 0 &&
                RideStatusConfig.map((res) => (
                  <StatusIndicator
                    key={res.text}
                    text={res.text}
                    fill={res.fill}
                  />
                ))}
            </Box>
          </Box> */}

          <CustomConfirmationDialog
            open={conformationDialog?.flagged}
            textArea={true}
            remarkref={remarkRef}
            isGroup={true}
            message={`Are you sure you want to unflag this ride with booking ID ${bookingId}?`}
            status="flagged"
            handleState={handleconformationDialog}
            flagbyid={updateFlagStatus}
            riderId={riderId}
          />
        </Box>
      )}
    </>
  );
};

export default TripsFlagged;
